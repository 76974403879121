import React from 'react';
import PropTypes from 'prop-types';

const DataTable = ({
  columns,
  list,
  currentPage,
  size,
  totalRows,
  handlePageChange,
  handleSizeChange,
  handleSearch,
  columnsConfig,
  tableClass,
  tableWrapperClass,
  noRowsMessage,
}) => {
  const pages = React.useMemo(() => {
    const totalPages = Math.ceil(totalRows / size);
    const pagesArray = [];

    for (let i = currentPage - 2; i <= currentPage + 2; i++) {
      if (i > 0 && i <= totalPages) {
        pagesArray.push(i);
      }
    }

    return pagesArray;
  }, [currentPage, totalRows, size]);

  return (
    <div className={tableWrapperClass}>
      <div className="d-flex justify-content-between mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Búsqueda"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <button
          type="button"
          className="btn btn-secondary ml-2"
          onClick={() => handleSearch('')}
        >
          Actualizar
        </button>
      </div>
      <table className={`table ${tableClass}`}>
        <thead className="thead-light">
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {list.length === 0 ? (
            <tr>
              <td colSpan={columns.length} className="text-center">
                {noRowsMessage}
              </td>
            </tr>
          ) : (
            list.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {Object.keys(columnsConfig).map((col, colIndex) => (
                  <td key={colIndex}>{columnsConfig[col](row[col])}</td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div className="d-flex align-items-center">
          <label htmlFor="sizeSelect" className="mr-2 mb-0">
            Mostrar
          </label>
          <select
            id="sizeSelect"
            className="form-control"
            style={{ width: 'auto' }}
            value={size}
            onChange={(e) => handleSizeChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        <span>
          Mostrando {Math.min((currentPage - 1) * size + 1, totalRows)} a{' '}
          {Math.min(currentPage * size, totalRows)} de {totalRows} registros
        </span>
        <ul className="pagination mb-0">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Anterior
            </button>
          </li>
          {pages.map((page) => (
            <li
              key={page}
              className={`page-item ${page === currentPage ? 'active' : ''}`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage >= Math.ceil(totalRows / size) ? 'disabled' : ''
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Siguiente
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

DataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleSizeChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  columnsConfig: PropTypes.object.isRequired,
  tableClass: PropTypes.string,
  tableWrapperClass: PropTypes.string,
  noRowsMessage: PropTypes.string,
};

DataTable.defaultProps = {
  tableClass: 'table table-striped table-sm',
  tableWrapperClass: 'table-responsive',
  noRowsMessage: 'No hay registros disponibles',
};

export default DataTable;
