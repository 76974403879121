import React from 'react';
import PropTypes from 'prop-types';
import styles from './multiSelect.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import useClickOutside from '#hooks/useClickOutside';

const MultiSelect = ({
  name,
  customClass,
  label,
  placeholder,
  items,
  itemKey,
  renderItem,
  renderInput,
  value,
  handleInputChange,
  valid,
  touched,
  errors,
}) => {
  const [dropdown, setDropdown] = React.useState(false);
  const toggleDropdown = () => setDropdown(dd => !dd);

  const clickRef = useClickOutside(dropdown, () => {
    toggleDropdown();
  });

  const handleItemClick = item => {
    const index = Array.isArray(value) ? value.findIndex(x => x[itemKey] === item[itemKey]) : -1;
    if (index !== -1) {
      const dummy = [...value.slice(0, index), ...value.slice(index + 1)];
      handleInputChange({
        name,
        value: dummy,
      });
    } else {
      const dummy = [...value];
      dummy.push(item);
      handleInputChange({
        name,
        value: dummy,
      });
    }
  };

  const display = React.useMemo(() => {
    let inputDisplay = '';
    let itemsDisplay = [...items];
    if (Array.isArray(value)) {
      itemsDisplay.forEach((item, i) => {
        if (value.findIndex(x => x[itemKey] === item[itemKey]) !== -1) {
          inputDisplay += `${renderInput(item)}, `;
          itemsDisplay[i].wafoSelected = true;
        } else {
          itemsDisplay[i].wafoSelected = false;
        }
      });
    } else {
      itemsDisplay = itemsDisplay.map(x => ({ ...x, wafoSelected: false }));
    }
    return {
      inputDisplay,
      itemsDisplay,
    };
  }, [items, itemKey, renderInput, value]);

  return (
    <div ref={clickRef} className={`form-group wafo-input ${customClass}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={`${styles['input']} ${dropdown && styles['input-down']}`}>
        <input
          type="text"
          id={name}
          name={name}
          placeholder={placeholder}
          className="form-control"
          value={display.inputDisplay}
          onClick={toggleDropdown}
          readOnly
        />
        <button type="button" className="btn btn-light" onClick={toggleDropdown}>
          <FontAwesomeIcon icon={dropdown ? faCaretUp : faCaretDown} />
        </button>
      </div>
      {dropdown && (
        <div className={styles['list']}>
          {items.length > 0 && (
            <ul>
              {display.itemsDisplay.map((item, i) => (
                <li key={i} onClick={() => handleItemClick(item)}>
                  <span>{renderItem(item)}</span>
                  <FontAwesomeIcon icon={item.wafoSelected ? faCheckSquare : faSquare} />
                </li>
              ))}
            </ul>
          )}
          {items.length === 0 && (
            <div className={styles['list-empty']}>
              <div>Sin elementos a elegir</div>
            </div>
          )}
        </div>
      )}
      {!valid && touched && (
        <ul className="errors">
          {errors.map(error => (
            <li key={error.error}>{error.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  items: PropTypes.array,
  itemKey: PropTypes.string,
  renderItem: PropTypes.func,
  renderInput: PropTypes.func,
  value: PropTypes.any,
  handleInputChange: PropTypes.func,
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.any),
};

MultiSelect.defaultProps = {
  customClass: '',
  label: '',
  placeholder: '',
  items: [],
  itemsKey: 'id',
  renderItem: item => (typeof item === 'string' ? item : 'Item option'),
  renderInput: item => (typeof item === 'string' ? item : 'Item option'),
  value: false,
  handleInputChange: f => f,
  valid: false,
  touched: false,
  errors: [],
};

export default MultiSelect;
