import React from 'react';
import PropTypes from 'prop-types';
import BooleanButtons from '../customForms/booleanButtons/booleanButtons';
import of from 'await-of';
import Input from '../../custom/Input';
import Select from '../../custom/Select';
import SelectRemote from '../../custom/SelectRemote';
import { useForm } from 'react-hook-form';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { roAPI } from '#utils/axiosAPI';

const initialValues = {
  id_route: null,
  id_city: null,
  id_device: null,
  code: null,
  brand: null,
  plate_number: null,
}

const FormBus = ({ history, match: { params }, loading, alert }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [vehicle, setVehicle] = React.useState(initialValues);
  const [cities, setCities] = React.useState([]);
  const [routes, setRoutes] = React.useState([]);
  const [busses, setBusses] = React.useState([]);
  const [idCity, setIdCity] = React.useState(null);
  const [idRoute, setIdRoute] = React.useState(null);

  React.useEffect(() => {
    async function getCities() {
      loading.set();
      const [res] = await of(
        roAPI.get('/cities', {
          params: { disabled: false },
        }),
      );
      setCities(res.rows);
      loading.stop();
    }
    async function getDevices() {
      loading.set();
      const [res] = await of(
        roAPI.get('/vehicles/devices', {}),
      );
      setBusses(res.rows);
      loading.stop();
    }
    getCities();
    getDevices();
  }, [loading]);

  React.useEffect(() => {
    if (idCity != null) {
      // eslint-disable-next-line no-inner-declarations
      async function getRoutes() {
        try {
          loading.set();
          const res = await roAPI.get(`/routes/city/${idCity}`, {
            params: {
              nocache: true,
              disabled: '0,1',
            },
          });
          setRoutes(res);
          loading.stop();
        } catch (error) {
          loading.stop();
        }
      }
      getRoutes();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCity]);

  React.useEffect(() => {
    if (params.id) {
      // eslint-disable-next-line no-inner-declarations
      async function fetchVehicleData() {
        try {
          loading.set();
          const [res] = await of(roAPI.get(`/vehicles/custom/${params.id}`));
          setVehicle({ ...res, id_route: res.route.id_route, id_city: res.city.id_city, id_device: { id: res.id_device }, ...(params.id ? { disabled: res.disabled } : {}) });
          setIdCity(res.city.id_city);
          setIdRoute(res.route.id_route);
          loading.stop();
        } catch (error) {
          loading.stop();
        }
      }
      fetchVehicleData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const onSubmit = async values => {
    try {
      loading.set();
      const newVehicle = { brand: values.brand, code: values.code, plate_number: values.plate_number, id_route: Number(values.id_route), id_city: Number(values.id_city), id_device: values.id_device.id, ...(params.id && typeof values.disabled == 'boolean' ? { disabled: values.disabled } : {}) };
      const res = params.id ? await roAPI.put(`/vehicles/custom/${params.id}`, newVehicle) : await roAPI.post('/vehicles/custom', newVehicle);
      setVehicle(prevVehicle => ({ ...prevVehicle, ...res }));
      history.push('/panel/autobuses/gestionar');
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
          <span>Regresar</span>
        </button>
      </div>
      <div className="col-12">
        <form key={params.id} formId="formBus" locale="es" onSubmit={handleSubmit(onSubmit)} values={vehicle}>
          <div className="col-12 col-md-6">
            <div className="row">
              <Select
                name="id_city"
                label="Ciudad"
                options={cities.map(x => ({
                  value: x.id_city,
                  label: x.name,
                }))}
                {...register('id_city', { required: 'Este campo es requerido' })}
                error={errors.id_city}
                onChange={({ target: { value } }) => setIdCity(value)}
              />
              <Input
                name="code"
                label="Clave"
                placeholder="Clave"
                {...register('code', {
                  required: 'Este campo es requerido',
                  maxLength: 254,
                })}
                error={errors.code}
              />
              <Input
                name="plate_number"
                label="Placa"
                placeholder="Placa"
                {...register('plate_number', {
                  maxLength: 254,
                })}
                error={errors.plate_number}
              />
              {
                params.id && <BooleanButtons
                  name="disabled"
                  label="Autobús activo"
                  customClass="col-12"
                  trueText="Activa"
                  falseText="Inactiva"
                  handleChange
                  invert
                />
              }
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <Select
                name="id_route"
                label="Ruta"
                options={routes.map(x => ({
                  value: x.id_route,
                  label: x.name,
                }))}
                {...register('id_route', { required: 'Este campo es requerido' })}
                error={errors.id_route}
                disabled={idCity == null}
                onChange={({ target: { value } }) => setIdRoute(value)}
              />
              <SelectRemote
                name="id_device"
                label="ID de dispositivo"
                placeholder="Seleccione un dispositivo"
                customClass="col-12"
                items={busses}
                filterItems={(items, query) => items.filter(item => (
                  item.id.toLowerCase().indexOf(query.toLowerCase()) !== -1
                  || item.code.toLowerCase().indexOf(query.toLowerCase()) !== -1
                ))}
                renderInput={item => `${item.id}`}
                renderItem={item => (
                  <p style={{ margin: '0' }}>
                    <span>{item.id}</span>
                    <br />
                    <span style={{ fontSize: '0.8em', color: '#777' }}>{item.code}</span>
                  </p>
                )}
                validations={{ required: true }}
                extraProps={{
                  autoComplete: 'off',
                }}
                handleChange
              />
              <Input
                name="brand"
                label="Marca"
                placeholder="Marca"
                {...register('brand', {
                  maxLength: 254,
                })}
                error={errors.brand}
              />
            </div>
          </div>
        </form>
        <button type="submit" form="formBus" className={`btn btn-une btn-submit btn-submit-center`}>
          Guardar cambios
        </button>
      </div>
    </div>
  );
};

FormBus.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.any,
  }),
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

FormBus.defaultProps = {
  history: {
    goBack: f => f,
    push: f => f,
  },
  match: {
    params: {},
  },
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  state => ({
    cities: state.cities,
    city: state.city,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    changeCity: city => dispatch(actions.changeCity(city)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(FormBus);
