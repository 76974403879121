import React from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, TileLayer, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { decode } from '@mapbox/polyline';
import { generatePBallTMarker, generatePBallMarker, generateMarker } from '#components/maps/extras/markers/markers';
import { boundsOfCity } from '#utils/utils';
import styles from './linesMap.module.css';

const hoverBallIcon = generatePBallTMarker();
const lastPosBallIcon = generatePBallMarker();
const poiIcon = generateMarker({ icon: 'poi' });

/**
 *
 * @param {number} city id of the current city.
 * @param {any || array} route line object or latlng array with positions.
 * @param {string} mapMode current "mode" to activate or deactivate some functions.
 * @param {function} addPoint callback, on map click adds pos to array.
 * @param {function} removePoint callback, on point click (marker), removes it from array.
 */
const LinesMap = ({ cities, city, route, mapMode, addPoint, removePoint, pois, showPois }) => {
  const [map, setMap] = React.useState(() => {
    const { center, bounds } = boundsOfCity(cities, city);
    return {
      zoom: 12,
      position: center,
      bounds,
    };
  });

  const [nextPoint, setNextpoint] = React.useState(null); // auxiliar state for route creation
  const [dragPoint, setDragpoint] = React.useState(null); // auxiliar state for route editing (middle point being dragged)

  React.useEffect(() => {
    const { center, bounds } = boundsOfCity(cities, city);
    setMap({
      position: center,
      zoom: 12,
      bounds,
    });
  }, [city, cities]);

  const mapRef = React.useRef(null);
  const polylineRef = React.useRef(null);

  const resetBounds = React.useCallback(() => {
    if (!mapMode) {
      if ((route.group_id || Array.isArray(route)) && polylineRef.current) {
        // reset bounds to route
        mapRef.current.leafletElement.flyToBounds(polylineRef.current.leafletElement.getBounds());
      } else if (cities.length) {
        // reset bounds to city
        mapRef.current.leafletElement.flyToBounds(boundsOfCity(cities, city).bounds);
        // mapRef.current.leafletElement.flyTo(citiesCoords[city], 12);
      }
    }
  }, [cities, city, route, mapMode]);

  React.useEffect(() => {
    resetBounds();
  }, [route, resetBounds]);

  const memoizedDecodedRoute = React.useMemo(() => {
    if (Array.isArray(route)) {
      if (nextPoint) {
        return [...route, nextPoint];
      }
      if (dragPoint) {
        const dummy = [...route];
        if (dragPoint.middle) {
          dummy.splice(dragPoint.index, 0, [dragPoint.latlng.lat, dragPoint.latlng.lng]);
        } else {
          dummy[dragPoint.index] = [dragPoint.latlng.lat, dragPoint.latlng.lng];
        }
        return dummy;
      }
      return route;
    } else {
      const decodedRoute = decode(route.polyline || '');
      if (decodedRoute) {
        return decodedRoute;
      }
    }
    return [];
  }, [route, nextPoint, dragPoint]);

  const memoizedDecodedChilds = React.useMemo(() => {
    const childs = [];
    if (route.route_unions && route.route_unions.length) {
      route.route_unions.forEach(rc => {
        const child = {
          points: [],
          route_configurations: rc.route_configurations,
          primary_color: rc.primary_color,
          secondary_color: rc.secondary_color,
        };
        const decodedChild = decode(rc.polyline || '');
        if (decodedChild) {
          child.points = decodedChild;
        }
        childs.push(child);
      });
    }
    return childs;
  }, [route]);

  // Array of points between the points of the line, for editing.
  const mediumPoints = React.useMemo(() => {
    if (mapMode !== 'edit') {
      return [];
    } else if (Array.isArray(route) && route.length > 1) {
      const middles = [];
      for (let i = 1; i < route.length; i++) {
        const lat = (route[i - 1][0] + route[i][0]) / 2;
        const lng = (route[i - 1][1] + route[i][1]) / 2;
        middles.push({
          index: i,
          latlng: [lat, lng],
        });
      }
      return middles;
    }
    return [];
  }, [mapMode, route]);

  const handleMapClick = ({ latlng }) => {
    if (mapMode === 'draw') {
      addPoint(latlng);
    }
  };

  // setting the auxiliar state to show the line where the next point is going to be
  const handleMapHover = ({ latlng }) => {
    if (mapMode === 'draw') {
      setNextpoint([latlng.lat, latlng.lng]);
    }
  };

  // dragging events for editting
  const handleMiddlepointDrag = ({ latlng }, index, middle = true) => setDragpoint({ index, latlng, middle });

  const handleMiddlepointDragend = (index, middle = true) => {
    addPoint(dragPoint.latlng, index, middle);
    setDragpoint(null);
  };

  const handleDeletePoint = index => removePoint(index);

  return (
    <div className={styles['map-wrapper']}>
      <Map
        ref={mapRef}
        center={map.position}
        zoom={map.zoom}
        maxZoom={18}
        animate
        bounds={map.bounds}
        className={styles['leaflet-container']}
        onClick={handleMapClick}
        onMouseMove={handleMapHover}
        onMouseOut={() => setNextpoint(null)}
        style={{ ...(mapMode === 'draw' && { cursor: 'pointer' }) }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {memoizedDecodedRoute.length !== 0 && <Polyline ref={polylineRef} positions={memoizedDecodedRoute} weight={4} color={"#0088FF"} />}
        {memoizedDecodedRoute.length > 2 && nextPoint && (
          <Marker
            position={memoizedDecodedRoute[memoizedDecodedRoute.length - 2]}
            icon={lastPosBallIcon}
            // onClick={removePoint}
            zIndexOffset={100}
          />
        )}
        {nextPoint && <Marker position={nextPoint} icon={hoverBallIcon} />}
        {mapMode === 'edit' &&
          mediumPoints.map((x, i) => (
            <Marker
              key={i}
              position={x.latlng}
              icon={hoverBallIcon}
              draggable
              onDrag={e => handleMiddlepointDrag(e, x.index)}
              onDragEnd={() => handleMiddlepointDragend(x.index)}
            />
          ))}
        {mapMode === 'edit' &&
          memoizedDecodedRoute.map((x, i) => (
            <Marker
              key={i}
              position={x}
              icon={lastPosBallIcon}
              draggable
              onDrag={e => handleMiddlepointDrag(e, i, false)}
              onDragEnd={() => handleMiddlepointDragend(i, false)}
              ondblclick={() => handleDeletePoint(i)}
            />
          ))}
        {showPois &&
          pois
            .filter(p => p.selected)
            .map(p => <Marker key={p.id_poi} position={[p.latitude, p.longitude]} icon={poiIcon} title={p.name} />)}
        {mapMode === '' &&
          memoizedDecodedChilds.length &&
          memoizedDecodedChilds.map((child, i) => <Polyline key={i} positions={child.points} weight={4} color={"#0088FF"} />)}
      </Map>
    </div>
  );
};

LinesMap.propTypes = {
  cities: PropTypes.array,
  city: PropTypes.string,
  route: PropTypes.any,
  mapMode: PropTypes.string,
  addPoint: PropTypes.func,
  removePoint: PropTypes.func,
  pois: PropTypes.array,
  showPois: PropTypes.bool,
};

LinesMap.defaultProps = {
  cities: [],
  city: '1',
  route: {},
  mapMode: '',
  addPoint: f => f,
  removePoint: f => f,
  pois: [],
  showPois: false,
};

export default LinesMap;
