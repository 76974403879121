import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import useDynamicTable from '../../../hooks/useDynamicTable'; 
import DataTable from '../DataTable';
import moment from 'moment';
import 'moment/locale/es';
import { connect as reduxConnect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';

const TableCities = ({ loading }) => {
  const getData = React.useCallback(
    async ({ size, page, search }) => {
      loading.set();
      const [resp, err] = await of(
        roAPI.get('/cities', {
          params: {
            limit: size,
            nocache: true,
            page,
            ...(search && { search }),
          },
        }),
      );
      
      loading.stop();

      if (!err) {
        return { rows: resp.rows, count: resp.count };
      }
      return { rows: [], count: 0 };
    },
    [loading],
  );

  const {
    count,
    list,
    currentPage,
    size,
    handlePageChange,
    handleSizeChange,
    handleSearch,
  } = useDynamicTable(getData, 1, 10);

  // Map the data to the correct fields
  const rows = list.map(row => ({
    id: row.id_city,
    status: row.disabled,
    name: row.name,
    date: row.created_at,
    options: { id: row.id_city, city: row },
  }));

  // Column configuration
  const columnsConfig = React.useMemo(
    () => ({
      id: val => <span>{val}</span>,
      status: val => (
        <span className={`badge ${!val ? 'badge-success' : 'badge-warning'}`}>
          {!val ? 'Active' : 'Inactive'}
        </span>
      ),
      name: val => <span>{val}</span>,
      date: val => <span>{moment(val).format('DD MMMM YYYY HH:mm')}</span>,
      options: val => (
        <Link
          className="btn btn-sm btn-primary"
          to={{ pathname: `/panel/ciudades/ciudad/${val.id}`, state: { city: val.city } }}
        >
          <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
          <span>Editar</span>
        </Link>
      ),
    }),
    [],
  );

  return (
    <div>
      <DataTable
        columns={['ID', 'Status', 'Name', 'Last Update', 'Options']}
        list={rows}
        currentPage={currentPage}
        size={size}
        totalRows={count}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
        handleSearch={handleSearch}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-responsive"
        noRowsMessage="No notifications"
      />
    </div>
  );
};


TableCities.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

TableCities.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableCities);
