import React from 'react';
import PropTypes from 'prop-types';
import styles from './imagePicker.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ImagePicker = ({ customClass, name, label, value, handleInputChange, serverBasePath, valid, touched, errors, help }) => {
  const refInput = React.useRef(null);

  const handleOnChange = ({ target: { files } }) => {
    if (files.length) {
      handleInputChange({
        target: {
          name,
          value: files[0],
        },
        type: 'change',
      });
    }
  };

  const removeFile = () => {
    handleInputChange({
      target: {
        name,
        value: '',
      },
      type: 'change',
    });
    refInput.current.value = '';
  };

  const preview = React.useMemo(() => {
    if (value && typeof value === 'string') {
      return `${serverBasePath}/${value}`
    } else if (value) {
      return URL.createObjectURL(value);
    }
    return null;
  }, [value, serverBasePath]);

  return (
    <div className={`wafoformimagepicker form-group wafo-input ${customClass}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        ref={refInput}
        type="file"
        id={name}
        name={name}
        accept=".png, .jpg, .jpeg"
        className={styles['input']}
        onChange={handleOnChange}
        ignoreinput="true"
      />
      <div className={styles['wrapper']}>
        {!value && (
          <label htmlFor={name} className={styles['placeholder']}>
            IMG
          </label>
        )}
        {value && (
          <div className={styles['preview']}>
            <img src={preview} alt="IMG Preview" />
            <button type="button" className={`btn btn-light ${styles['remove-btn']}`} onClick={removeFile}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        )}
      </div>
      <div className={styles['desc']}>
        {!value && <span>Clic para seleccionar imagen</span>}
        {value && typeof value === 'object' && (
          <>
            <span>{value.name}</span>
            <span style={{ fontSize: '0.85em', marginLeft: '.5rem' }}>{(value.size / 1024).toFixed(2)}kb</span>
          </>
        )}
        {value && typeof value === 'string' && <span>{value}</span>}
      </div>
      <p className={styles['help']}>
        {help}
      </p>
      {!valid && touched && (
        <ul className="errors">
          {errors.map(error => (
            <li key={error.error}>{error.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

ImagePicker.propTypes = {
  customClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  handleInputChange: PropTypes.func,
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.any),
  validations: PropTypes.object,
  serverBasePath: PropTypes.string,
  help: PropTypes.string,
};

ImagePicker.defaultProps = {
  customClass: '',
  label: undefined,
  value: '',
  handleInputChange: f => f,
  valid: false,
  touched: false,
  errors: [],
  validations: {},
  serverBasePath: '',
  help: '',
};

export default ImagePicker;
