import React from 'react';
import PropTypes from 'prop-types';

const TextArea = React.forwardRef(({
  label,
  placeholder,
  name,
  error,
  ...props
}, ref) => {
  return (
    <div className="mb-4 flex flex-col">
      {label && <label className="block font-neue-haas-grotesk text-xl font-medium mb-1 text-black">{label}</label>}
      <textarea
        id={name}
        name={name}
        ref={ref}
        className={`w-full border ${error ? 'border-red-500' : 'border-gray-300'} hover:border-system1 rounded p-2 transition bg-white font-neue-haas-grotesk text-lg`}
        placeholder={placeholder}
        {...props}
      />
      {error && <p className="mt-2 text-xs text-red-500 font-neue-haas-grotesk">{error.message}</p>}
    </div>
  );
});

TextArea.displayName = 'TextArea';

TextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
};

export default TextArea;
