import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../custom/Input';
import Select from '../../custom/Select';
import TextArea from '../../custom/TextArea';
import BooleanButtons from '../customForms/booleanButtons/booleanButtons';
import styles from './formLine.module.css';
import TimePicker from '#components/forms/customForms/timePicker/timePicker';
import ColorPicker from '../customForms/colorPicker/colorPicker';
import ImagePicker from '../customForms/imagePicker/imagePicker';
import MultiSelect from '../customForms/multiSelect/multiSelect';
import { useForm } from 'react-hook-form';
import { roAPI } from '#utils/axiosAPI';

// TODO:
// Te quedaste buscando como hacer reload a la info de la forma.
// Falta ordenar por orden de prioridad las configs cuando existen.

const FormLine = ({ onSubmit, initialValues, cities, onCityChange, tabs, routes, onParentChange, loading, updateRoutes }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [weekTab, setWeekTab] = React.useState('week');
  const [busTypes, setBusTypes] = React.useState([]);

  const initialV = React.useMemo(() => {
    return {
      ...initialValues.metadata,
      ...initialValues,
      // unit_config: initialValues.route_configurations,
    };
  }, [initialValues]);

  const possibleParents = React.useMemo(() => {
    const parents = routes.filter(r => r.id_route !== initialValues.id_route);
    parents.unshift({
      id_route: '',
      name: 'Sin línea padre',
    });
    return parents;
  }, [routes, initialValues]);

  React.useEffect(() => {
    async function getTypes() {
      try {
        loading.set();
        const types = await roAPI.get('/configurations/icons', {
          params: {
            limit: 999,
            page: 1,
          },
        });
        setBusTypes(types.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getTypes();
  }, [loading]);

  const deleteConfigs = async () => {
    try {
      loading.set();
      const allConfigs = await roAPI.get('/routes/configurations');
      const routeConfigs = Object.entries(allConfigs).reduce((accumulator, [id_route, configs]) => {
        if (initialValues.id_route === id_route) {
          accumulator = configs;
        }
        return accumulator;
      }, []);
      await Promise.all(routeConfigs.map(config => roAPI.delete(`/routes/configurations/${config.id_rc}`)));
      updateRoutes();
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  };

  return (
    <div>
      <form id="formLine" locale="es" onSubmit={handleSubmit(onSubmit)} values={initialV}>
        <div className="col-12">
          <div className="row" style={{ ...(tabs !== 'info' && { display: 'none' }) }}>
            <div className="col-12">
              <Select
                name="id_city"
                label="Ciudad"
                defaultValue=""
                options={cities.map(x => ({
                  value: x.id_city,
                  label: x.name,
                }))}
                {...register('id_city', { required: 'Este campo es requerido' })}
                error={errors.id_city}
                onChange={onCityChange}
                disabled={!!initialValues.id_route}
              />
            </div>

            <div className="col-12">
              <Select
                name="id_parent_route"
                label="Línea padre"
                defaultValue=""
                options={possibleParents.map(x => ({
                  value: x.id_route,
                  label: x.name,
                }))}
                {...register('id_parent_route')}
                onChange={onParentChange}
              />
            </div>

            <div className="col-6">
              <Input
                name="group_id"
                label="Id de línea"
                placeholder="Id de línea"
                {...register('group_id', { required: 'Este campo es requerido' })}
                error={errors.group_id}
              />
            </div>

            <div className="col-6">
              <Input
                type="number"
                name="internal_order"
                label="Orden de línea"
                placeholder="0"
                {...register('internal_order', { required: 'Este campo es requerido', min: 1 })}
                error={errors.internal_order}
              />
            </div>

            <div className="col-12">
              <Input
                name="name"
                label="Nombre de línea"
                placeholder="Nombre de línea"
                {...register('name', { required: 'Este campo es requerido' })}
                error={errors.name}
              />
            </div>

            <div className="col-12">
              <TextArea
                name="description"
                label="Descripción de línea"
                placeholder="Descripción de línea"
                {...register('description', { maxLength: 255 })}
                error={errors.description}
              />
            </div>

            <div className="col-12">
              <BooleanButtons
                name="disabled"
                label="Ruta activa"
                trueText="Activa"
                falseText="Inactiva"
                invert
                {...register('disabled')}
              />
            </div>
          </div>

          <div className="row" style={{ ...(tabs !== 'times' && { display: 'none' }) }}>
            <div className="col-6">
              <TimePicker
                name="duration"
                label="Duración de recorrido"
                {...register('duration', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                    message: 'Hora invalida',
                  },
                })}
                error={errors.duration}
              />
            </div>

            <div className="col-6">
              <Input
                type="number"
                name="distance"
                label="Longitud de línea (m)"
                placeholder="0.0"
                {...register('distance', { required: 'Este campo es requerido' })}
                error={errors.distance}
              />
            </div>

            <div className="col-12">
              <ul className={`nav nav-tabs ${styles['tabs']}`}>
                <li className="nav-item">
                  <button
                    type="button"
                    className={`btn btn-link nav-link ${weekTab === 'week' && 'active'}`}
                    onClick={() => setWeekTab('week')}
                  >
                    Lun - Vie
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className={`btn btn-link nav-link ${weekTab === 'sat' && 'active'}`}
                    onClick={() => setWeekTab('sat')}
                  >
                    Sábado
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className={`btn btn-link nav-link ${weekTab === 'sun' && 'active'}`}
                    onClick={() => setWeekTab('sun')}
                  >
                    Domingo
                  </button>
                </li>
              </ul>
            </div>

            <div className="col-12" style={{ ...(weekTab !== 'week' && { display: 'none' }) }}>
              <div className="row">
                <div className="col-6">
                  <Input
                    type="number"
                    name="service_units"
                    label="Unidades en servicio"
                    placeholder="0"
                    {...register('service_units', { required: 'Este campo es requerido' })}
                    error={errors.service_units}
                  />
                </div>

                <div className="col-6">
                  <TimePicker
                    name="service_frequency"
                    label="Frecuencia de paso"
                    {...register('service_frequency', {
                      required: 'Este campo es requerido',
                      pattern: {
                        value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                        message: 'Hora invalida',
                      },
                    })}
                    error={errors.service_frequency}
                  />
                </div>

                <div className="col-6">
                  <TimePicker
                    name="service_start"
                    label="Hora de inicio"
                    {...register('service_start', {
                      required: 'Este campo es requerido',
                      pattern: {
                        value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                        message: 'Hora invalida',
                      },
                    })}
                    error={errors.service_start}
                  />
                </div>

                <div className="col-6">
                  <TimePicker
                    name="service_end"
                    label="Hora de fin"
                    {...register('service_end', {
                      required: 'Este campo es requerido',
                      pattern: {
                        value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                        message: 'Hora invalida',
                      },
                    })}
                    error={errors.service_end}
                  />
                </div>
              </div>
            </div>

            <div className="col-12" style={{ ...(weekTab !== 'sat' && { display: 'none' }) }}>
              <div className="row">
                <div className="col-6">
                  <Input
                    type="number"
                    name="service_units_saturday"
                    label="Unidades en servicio"
                    placeholder="0"
                    {...register('service_units_saturday', { required: 'Este campo es requerido' })}
                    error={errors.service_units_saturday}
                  />
                </div>

                <div className="col-6">
                  <TimePicker
                    name="service_frequency_saturday"
                    label="Frecuencia de paso"
                    {...register('service_frequency_saturday', {
                      required: 'Este campo es requerido',
                      pattern: {
                        value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                        message: 'Hora invalida',
                      },
                    })}
                    error={errors.service_frequency_saturday}
                  />
                </div>

                <div className="col-6">
                  <TimePicker
                    name="service_start_saturday"
                    label="Hora de inicio"
                    {...register('service_start_saturday', {
                      required: 'Este campo es requerido',
                      pattern: {
                        value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                        message: 'Hora invalida',
                      },
                    })}
                    error={errors.service_start_saturday}
                  />
                </div>

                <div className="col-6">
                  <TimePicker
                    name="service_end_saturday"
                    label="Hora de fin"
                    {...register('service_end_saturday', {
                      required: 'Este campo es requerido',
                      pattern: {
                        value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                        message: 'Hora invalida',
                      },
                    })}
                    error={errors.service_end_saturday}
                  />
                </div>
              </div>
            </div>

            <div className="col-12" style={{ ...(weekTab !== 'sun' && { display: 'none' }) }}>
              <div className="row">
                <div className="col-6">
                  <Input
                    type="number"
                    name="service_units_sunday"
                    label="Unidades en servicio"
                    placeholder="0"
                    {...register('service_units_sunday', { required: 'Este campo es requerido' })}
                    error={errors.service_units_sunday}
                  />
                </div>

                <div className="col-6">
                  <TimePicker
                    name="service_frequency_sunday"
                    label="Frecuencia de paso"
                    {...register('service_frequency_sunday', {
                      required: 'Este campo es requerido',
                      pattern: {
                        value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                        message: 'Hora invalida',
                      },
                    })}
                    error={errors.service_frequency_sunday}
                  />
                </div>

                <div className="col-6">
                  <TimePicker
                    name="service_start_sunday"
                    label="Hora de inicio"
                    {...register('service_start_sunday', {
                      required: 'Este campo es requerido',
                      pattern: {
                        value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                        message: 'Hora invalida',
                      },
                    })}
                    error={errors.service_start_sunday}
                  />
                </div>

                <div className="col-6">
                  <TimePicker
                    name="service_end_sunday"
                    label="Hora de fin"
                    {...register('service_end_sunday', {
                      required: 'Este campo es requerido',
                      pattern: {
                        value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                        message: 'Hora invalida',
                      },
                    })}
                    error={errors.service_end_sunday}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ ...(tabs !== 'style' && { display: 'none' }) }}>
            <div className="col-12">
              <h5>Personalización de línea</h5>
            </div>

            <div className="col-12">
              <ColorPicker
                name="primary_color"
                label="Color primario"
                {...register('primary_color')}
              />
            </div>

            <div className="col-12">
              <ColorPicker
                name="secondary_color"
                label="Color secundario"
                {...register('secondary_color')}
              />
            </div>

            <div className="col-12">
              <ImagePicker
                name="logo"
                label="Imagen de línea"
                serverBasePath={process.env.REACT_APP_IMG_ROUTE}
                {...register('logo')}
              />
            </div>

            <div className="col-12">
              <h5>Personalización de unidades</h5>
            </div>

            {(!initialV.route_configurations || initialV.route_configurations.length < 1) && (
              <div className="col-12 col-md-12">
                <MultiSelect
                  name="unit_config"
                  label="Tipo de unidad"
                  placeholder="Seleccione tipos"
                  itemKey="id_icon"
                  items={busTypes}
                  renderItem={item => item.name}
                  renderInput={item => item.name}
                  {...register('unit_config')}
                />
              </div>
            )}

            {initialV.route_configurations && initialV.route_configurations.length > 0 && (
              <div className="col-12">
                <ul className={styles['config-list']}>
                  {initialV.route_configurations.map((config, i) => (
                    <li key={i}>
                      <span>
                        <strong>{config.name}</strong>
                      </span>
                      <img
                        src={`${process.env.REACT_APP_IMG_BUSICON}/${config.image_icon}`}
                        alt={config.name}
                      />
                    </li>
                  ))}
                </ul>
                <button
                  type="button"
                  className={`btn btn-sm btn-link-eliminar ${styles['delete-config']}`}
                  onClick={deleteConfigs}
                >
                  Eliminar configuración
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

FormLine.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.any,
  cities: PropTypes.array,
  onCityChange: PropTypes.func,
  tabs: PropTypes.string,
  routes: PropTypes.array,
  onParentChange: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  updateRoutes: PropTypes.func,
};

FormLine.defaultProps = {
  onSubmit: f => f,
  initialValues: {},
  cities: [],
  onCityChange: f => f,
  tabs: '',
  routes: [],
  onParentChange: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
  updateRoutes: f => f
};

export default FormLine;
