import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMapMarkerAlt, faCalendarAlt, faList } from '@fortawesome/free-solid-svg-icons';
import TabsCard from '#components/UI/tabsCard/tabsCard';
import TableCredentialsLocations from '#components/tables/credentials/tableCredentialsLocations';
import FormRechargePoint from '#components/forms/credentials/formRechargePoint';
import FormCalendar from '#components/forms/credentials/formCalendar';
import TableCredentialsInformation from '#components/tables/credentials/tableCredentialsInformation';
import FormCredentialsInformation from '#components/forms/credentials/formCredentialsInformation';

const Credentials = () => (
  <TabsCard
    title="Credencialización y recarga"
    headerButtons={[
      <Route
        exact
        key="/panel/credencializacion/ubicaciones"
        path="/panel/credencializacion/ubicaciones"
        render={() => (
          <Link to="/panel/credencializacion/ubicaciones/rp" className="btn btn-primary">
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
            Agregar punto
          </Link>
        )}
      />,
      <Route
        exact
        key="/panel/credencializacion/informacion"
        path="/panel/credencializacion/informacion"
        render={() => (
          <Link to="/panel/credencializacion/informacion/nueva" className="btn btn-primary">
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
            Agregar información
          </Link>
        )}
      />,
    ]}
    tabs={[
      {
        exact: false,
        to: '/panel/credencializacion/ubicaciones',
        render: (
          <>
            <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '.5rem' }} />
            Ubicaciones
          </>
        ),
      },
      {
        exact: true,
        to: '/panel/credencializacion/calendario',
        render: (
          <>
            <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '.5rem' }} />
            Calendario SEC
          </>
        ),
      },
      {
        exact: false,
        to: '/panel/credencializacion/informacion',
        render: (
          <>
            <FontAwesomeIcon icon={faList} style={{ marginRight: '.5rem' }} />
            Información
          </>
        ),
      },
    ]}
    routes={[
      <Route
        exact
        key="/panel/credencializacion/ubicaciones"
        path="/panel/credencializacion/ubicaciones"
        component={TableCredentialsLocations}
      />,
      <Route
        exact
        key="/panel/credencializacion/ubicaciones/rp"
        path="/panel/credencializacion/ubicaciones/rp"
        component={FormRechargePoint}
      />,
      <Route
        exact
        key="/panel/credencializacion/ubicaciones/rp/:id"
        path="/panel/credencializacion/ubicaciones/rp/:id"
        component={FormRechargePoint}
      />,
      <Route
        exact
        key="/panel/credencializacion/calendario"
        path="/panel/credencializacion/calendario"
        component={FormCalendar}
      />,
      <Route
        exact
        key="/panel/credencializacion/informacion"
        path="/panel/credencializacion/informacion"
        component={TableCredentialsInformation}
      />,
      <Route
        exact
        key="/panel/credencializacion/informacion/nueva"
        path="/panel/credencializacion/informacion/nueva"
        component={FormCredentialsInformation}
      />,
      <Route
        exact
        key="/panel/credencializacion/informacion/nueva/:id"
        path="/panel/credencializacion/informacion/nueva/:id"
        component={FormCredentialsInformation}
      />,
      <Redirect
        key="/panel/credencializacion"
        from="/panel/credencializacion"
        to="/panel/credencializacion/ubicaciones"
      />,
    ]}
  />
);

Credentials.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Credentials.defaultProps = {
  match: {},
};

export default Credentials;
