import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '@wafo/table';
import useLoading from '#hooks/redux/useLoading';
import { roAPI } from '#utils/axiosAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill, faBus, faInfo } from '@fortawesome/free-solid-svg-icons';
import styles from './users.module.css';
import moment from 'moment';

const TableCredentialsLogs = ({ card_id }) => {
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const loading = useLoading();

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      try {
        loading.set();
        const resp = await roAPI.get('/admin/users/cards/logs', {
          params: {
            limit: size,
            page,
            ...(search && { search }),
            order: '-id_card_log',
            cardId: card_id,
          },
        });
        setRows(
          resp.rows.map(row => ({
            id: row.id_card_log,
            type: row.transaction_type,
            balance: {
              prev: row.prev_balance,
              final: row.final_balance,
            },
            date: row.transaction_date,
          })),
        );
        setTotalRows(resp.count);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    },
    [card_id, loading],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: val => <span>#{val}</span>,
      type: val => {
        switch (val) {
          case 0:
            return (
              <span className={`badge badge-success ${styles['transaction-badge']}`}>
                <FontAwesomeIcon icon={faMoneyBill} style={{ marginRight: '.5rem' }} />
                <span>Recarga</span>
              </span>
            );
          case 3:
            return (
              <span className={`badge badge-primary ${styles['transaction-badge']}`}>
                <FontAwesomeIcon icon={faBus} style={{ marginRight: '.5rem' }} />
                <span>Pasaje</span>
              </span>
            );
          case -5:
            return (
              <span className={`badge badge-secondary ${styles['transaction-badge']}`}>
                <FontAwesomeIcon icon={faInfo} style={{ marginRight: '.5rem' }} />
                <span>Validación</span>
              </span>
            );
          default:
            return null;
        }
      },
      balance: val => (
        <div style={{ lineHeight: 1 }}>
          <span>${val.final}</span>
          <br />
          <span style={{ fontSize: '.8em', color: '#777' }}>Movimiento: ${Math.abs(val.prev - val.final)}</span>
        </div>
      ),
      date: val => <span>{moment(val).format('DD MMMM YYYY HH:mm')}</span>,
    }),
    [],
  );

  return (
    <div>
      <DataTable
        locale="es"
        columns={['ID', 'Transacción', 'Balance', 'Fecha']}
        rows={rows}
        totalRows={totalRows}
        onPagination={getData}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        noRowsMessage="Sin movimientos registrados"
        controls={<h5>Historial de movimientos</h5>}
      />
    </div>
  );
};

TableCredentialsLogs.propTypes = {
  card_id: PropTypes.string,
};

export default TableCredentialsLogs;
