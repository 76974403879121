import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import { WafoFormSelect } from '@wafo/forms';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import LeafletLinesMap from '#components/maps/linesMap/linesMap';
import { roAPI } from '#utils/axiosAPI';
import styles from './linesMap.module.css';

const LinesMap = ({ cities, city, routes, changeCity, updateRoutes, loading }) => {
  const [route, setRoute] = React.useState({});

  React.useEffect(() => {
    async function fetchRoutes() {
      loading.set();
      const [res] = await of(
        roAPI.get(`/routes/city/${city}`, {
          params: {
            nocache: true,
            disabled: '0,1',
          },
        }),
      );
      updateRoutes(res, false);
      loading.stop();
    }
    fetchRoutes();
  }, [city, updateRoutes, loading]);

  const handleCityChange = ({ target: { value } }) => changeCity(value);

  return (
    <div className="row">
      <div className="col-12 col-md-4">
        <div className="row">
          <WafoFormSelect
            name="city"
            customClass="col-12"
            label="Ciudad"
            defaultValue="Selecciona la ciudad"
            options={cities.map(x => ({
              value: x.id_city,
              display: x.name,
            }))}
            value={city}
            onChangeCallback={handleCityChange}
          />

          <div className={`col-12 ${styles['lines-list']}`}>
            <label>Líneas de la ciudad</label>
            {routes.length > 0 && (
              <ul>
                {routes.map(r => (
                  <li key={r.id_route}>
                    <div onClick={() => setRoute(r)}>
                      <span>
                        <FontAwesomeIcon
                          icon={faCircle}
                          style={{ marginRight: '.5rem' }}
                          color={r.disabled ? '#dc3545' : '#28a745'}
                        />
                        <span>{r.name}</span>
                      </span>
                      <div>
                        <Link
                          className="btn btn-sm btn-link"
                          to={{ pathname: `/panel/lineas/linea/${r.id_route}`, state: { route: r } }}
                        >
                          Editar
                        </Link>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </div>
                    </div>
                    {r.route_unions.length > 0 && (
                      <ul>
                        {r.route_unions.map(ru => (
                          <li key={ru.id_route} onClick={() => setRoute(ru)}>
                            <Link to={{ pathname: `/panel/lineas/linea/${ru.id_route}`, state: { route: ru } }}>
                              <FontAwesomeIcon
                                icon={faCircle}
                                style={{ marginRight: '.5rem', fontSize: '0.8em' }}
                                color={ru.disabled ? '#dc3545' : '#28a745'}
                              />
                              {ru.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
            {routes.length < 1 && <p className={styles['empty']}>Sin líneas asignadas.</p>}
          </div>
        </div>
      </div>
      <div className="col-12 col-md-8">
        <div className={styles['map']}>
          <LeafletLinesMap city={city} route={route} cities={cities} />
        </div>
      </div>
    </div>
  );
};

LinesMap.propTypes = {
  cities: PropTypes.array,
  city: PropTypes.string,
  routes: PropTypes.array,
  changeCity: PropTypes.func,
  updateRoutes: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

LinesMap.defaultProps = {
  cities: [],
  city: '',
  routes: [],
  changeCity: f => f,
  updateRoutes: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  state => ({
    cities: state.cities,
    city: state.city,
    routes: state.routes,
  }),
  dispatch => ({
    updateRoutes: (routes, filter) => dispatch(actions.updateRoutes(routes, filter)),
    changeCity: city => dispatch(actions.changeCity(city)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(LinesMap);
