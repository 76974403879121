import useDebounce from './useDebounce';
import { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useDynamicTable = (getData, initialPage = 1, initialSize = 10) => {
  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [size, setSize] = useState(initialSize);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useDebounce(searchTerm, 500);

  const location = useLocation();
  const history = useHistory();

  const updateQueryParams = useCallback((params) => {
    const searchParams = new URLSearchParams(location.search);
    Object.keys(params).forEach(key => {
      if (params[key] !== undefined) {
        searchParams.set(key, params[key]);
      } else {
        searchParams.delete(key);
      }
    });
    history.push({ search: searchParams.toString() });
  }, [history, location.search]);

  const loadItems = useCallback(async () => {
    const { rows, count } = await getData({ size, page: currentPage, search: debouncedSearch });
    setList(rows);
    setCount(parseInt(count, 10));
  }, [currentPage, size, debouncedSearch, getData]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setCurrentPage(parseInt(params.get('page')) || initialPage);
    setSize(parseInt(params.get('size')) || initialSize);
    setSearchTerm(params.get('search') || '');
  }, [location.search, initialPage, initialSize]);

  useEffect(() => {
    loadItems();
  }, [currentPage, size, debouncedSearch, loadItems]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    updateQueryParams({ search: term, page: 1 });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateQueryParams({ page });
  };

  const handleSizeChange = (value) => {
    setSize(parseInt(value, 10));
    setCurrentPage(1);
    updateQueryParams({ size: value, page: 1 });
  };

  return {
    count,
    list,
    currentPage,
    size,
    handlePageChange,
    handleSizeChange,
    handleSearch,
  };
};

export default useDynamicTable;
