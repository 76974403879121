import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { Switch, Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TableNotifications from '#components/tables/notifications/tableNotifications';
import FormNotification from '#components/forms/notifications/formNotification';
import { roAPI } from '#utils/axiosAPI';
import Notification from './notification';

const Notificaciones = ({ match, cities, updateCities, loading }) => {
  React.useEffect(() => {
    async function fetchCities() {
      loading.set();
      const [res] = await of(
        roAPI.get('/cities', {
          params: {
            disabled: false,
            nocache: true,
          },
        }),
      );
      updateCities(res.rows);
      loading.stop();
    }
    fetchCities();
  }, [updateCities, loading]);

  return (
    <div data-testid="Notificaciones">
      <div className="section-header">
        <h2 className="title">Notificaciones</h2>
        <Route
          exact
          path={`${match.url}/`}
          render={() => (
            <Link to="/panel/notificaciones/nueva" className="btn btn-primary">
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
              Crear notificación
            </Link>
          )}
        />
      </div>

      <div className="card">
        <div className="card-body">
          <Switch>
            <Route exact path={`${match.url}/`} render={props => <TableNotifications {...props} cities={cities} />} />
            <Route
              exact
              path={`${match.url}/nueva`}
              render={props => (
                <>
                  <FormNotification {...props} cities={cities} />
                </>
              )}
            />
            <Route
              exact
              path={`${match.url}/notificacion/:id`}
              render={props => <Notification {...props} cities={cities} />}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

Notificaciones.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  cities: PropTypes.array,
  updateCities: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

Notificaciones.defaultProps = {
  match: {},
  cities: [],
  updateCities: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  state => ({
    cities: state.cities,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(Notificaciones);
