import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faRoute,
  faCheckSquare,
  faToggleOn,
  faToggleOff,
  faClock,
  faSlidersH,
  faFileAlt,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { getDistance } from 'geolib';
import { decode, encode } from '@mapbox/polyline';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import LeafletLinesMap from '#components/maps/linesMap/linesMap';
import FormLine from '#components/forms/formLine/formLine';
import { roAPI } from '#utils/axiosAPI';
import styles from './newLine.module.css';
import useDebounce from '#hooks/useDebounce';
import confirmModal from '#components/modals/confirm/confirm';

// const catorce = require('../../../../assets/ruta14norte.json');

const NewLine = ({
  history,
  match: { params },
  location,
  cities,
  city,
  changeCity,
  routes,
  updateRoutes,
  loading,
  pushAlert,
}) => {
  const [tabs, setTabs] = React.useState('info');
  const [mapMode, setMapmode] = React.useState('');

  const handleMapmode = mode => {
    if (mode === mapMode) {
      setMapmode('');
    } else {
      setMapmode(mode);
    }
  };

  /** **************************************** */
  /** Cities */
  /** **************************************** */

  const [formCities, setFormCities] = React.useState([]);

  React.useEffect(() => {
    async function getCities() {
      try {
        loading.set();
        const resp = await roAPI.get('/cities', {
          params: {
            // disabled: false
          },
        });
        setFormCities(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getCities();
  }, [loading]);

  /** **************************************** */
  /** Line */
  /** **************************************** */
  const [line, setLine] = React.useState({});
  const [valid, setValid] = React.useState({
    info: true,
    times: true,
    style: true,
  });

  React.useEffect(() => {
    if (location.state && location.state.route) {
      let route = routes.find(x => x.id_route === location.state.route.id_route);
      if (!route) {
        route = location.state.route;
      }
      const decodedRoute = decode(route.polyline || '');
      setRoutePoints(decodedRoute);
      setLine(route);
      if (route.route_pois && route.route_pois.length) {
        advancedSetPois(route.route_pois.map(p => ({ ...p, selected: true })));
      }
    }
  }, [location, routes]);

  /* React.useEffect(() => {
    if (params.id) {
      loading.set();
      const route = routes.find(x => x.id_route === params.id);
      let decodedRoute = decode(route.polyline || '');
      // decodedRoute = decodedRoute.map(cords => [cords[1], cords[0]]);
      setRoutePoints(decodedRoute);
      setLine(route);
      if (route.route_pois.length) {
        advancedSetPois(route.route_pois.map(p => ({ ...p, selected: true })));
      }
      loading.stop();
    }
  }, [routes, params.id, loading]); */

  const getRoutes = React.useCallback(async () => {
    try {
      loading.set();
      const res = await roAPI.get(`/routes/city/${city}`, {
        params: {
          nocache: true,
          disabled: '0,1',
        },
      });
      updateRoutes(res, false);
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  }, [city, updateRoutes, loading]);

  React.useEffect(() => {
    getRoutes();
  }, [getRoutes]);

  const submitLine = async (form, values) => {
    if (!form.valid) {
      checkValidity(form);
      pushAlert({
        type: 'warning',
        title: 'Alerta',
        message:
          'Hay un problema con uno o más de los campos de la forma. Por favor siga las instrucciones en pantalla.',
        timer: 3000,
      });
    } else {
      try {
        loading.set();
        const newLine = {
          id_city: values.id_city,
          id_parent_route: values.id_parent_route ? values.id_parent_route : null,
          name: values.name,
          special_name: values.name,
          group_id: values.group_id,
          // internal_id: values.group_id,
          ...(values.description && { description: values.description }),
          internal_order: values.internal_order,
          polyline: encode(routePoints.map(p => [p[0], p[1]])),
          disabled: !!values.disabled,
          // handicap_enabled: !!values.handicap_enabled,
          handicap_enabled: false,
          ...(values.primary_color && { primary_color: values.primary_color }),
          ...(values.secondary_color && { secondary_color: values.secondary_color }),
          metadata: {
            distance: values.distance,
            duration: values.duration,
            service_units: values.service_units,
            service_frequency: values.service_frequency,
            service_start: values.service_start,
            service_end: values.service_end,
            service_units_saturday: values.service_units_saturday,
            service_frequency_saturday: values.service_frequency_saturday,
            service_start_saturday: values.service_start_saturday,
            service_end_saturday: values.service_end_saturday,
            service_units_sunday: values.service_units_sunday,
            service_frequency_sunday: values.service_frequency_sunday,
            service_start_sunday: values.service_start_sunday,
            service_end_sunday: values.service_end_sunday,
          },
        };
        const res = params.id
          ? await roAPI.put(`/routes/${params.id}`, newLine)
          : await roAPI.post('/routes/', newLine);
        if (res.id_route && !res.id_parent_route) {
          const selectedPOIs = pois.filter(x => x.selected);
          const toAdd = line.id_route
            ? selectedPOIs.filter(x => line.route_pois.findIndex(y => y.id_poi === x.id_poi) === -1)
            : selectedPOIs;
          const toDelete = line.id_route
            ? line.route_pois.filter(x => selectedPOIs.findIndex(y => y.id_poi === x.id_poi) === -1)
            : [];

          await Promise.all(toDelete.map(p => roAPI.delete(`/routes/${res.id_route}/poi/${p.id_poi}`)));
          await Promise.all(toAdd.map(p => roAPI.post(`/routes/${res.id_route}/poi/${p.id_poi}`)));
        }
        if (values.logo) {
          if (typeof values.logo !== 'string') {
            const formModel = new FormData();
            formModel.append('image', values.logo);
            await roAPI.post(`/routes/${res.id_route}/logo`, formModel);
          }
        }

        // Unit configurations
        if (values.unit_config && values.unit_config.length) {
          await Promise.all(
            values.unit_config.map(config =>
              roAPI.post('/routes/configurations', {
                id_route: res.id_route,
                id_icon: config.id_icon,
                order: config.order || 10,
              }),
            ),
          );
        }

        history.push('/panel/lineas');
        loading.stop();
      } catch (err) {
        console.error(err);
        loading.stop();
      }
    }
  };

  const handleOnDelete = id => {
    async function deleteLine() {
      loading.set();
      const [, error] = await of(roAPI.delete(`/routes/${id}`));
      if (!error) {
        history.push('/panel/lineas');
      }
      loading.stop();
    }
    confirmModal({
      message: (
        <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
          Eliminar líneas es irreversible, se reiniciara el cache y los cambios se reflejaran inmediatamente en la
          aplicación.
          <br />
          ¿Desea continuar?
        </p>
      ),
      buttons: [
        {
          label: 'Cancelar',
          class: 'btn-secondary',
        },
        {
          label: 'Aceptar',
          class: 'btn-une',
          onClick: deleteLine,
        },
      ],
    });
  };

  const checkValidity = form => {
    setValid(() => {
      const newValidity = {
        info: true,
        times: true,
        style: true,
      };
      if (!form.id_city.valid || !form.group_id.valid || !form.internal_order.valid || !form.name.valid) {
        newValidity.info = false;
      }
      if (
        !form.duration.valid ||
        !form.distance.valid ||
        !form.service_units.valid ||
        !form.service_frequency.valid ||
        !form.service_start.valid ||
        !form.service_end.valid ||
        !form.service_units_saturday.valid ||
        !form.service_frequency_saturday.valid ||
        !form.service_start_saturday.valid ||
        !form.service_end_saturday.valid ||
        !form.service_units_sunday.valid ||
        !form.service_frequency_sunday.valid ||
        !form.service_start_sunday.valid ||
        !form.service_end_sunday.valid
      ) {
        newValidity.times = false;
      }
      return newValidity;
    });
  };

  const handleParentChange = parent => {
    setLine(prevLine => ({
      ...prevLine,
      id_parent_route: parent,
    }));
  };

  /** **************************************** */
  /** Polyline */
  /** **************************************** */
  const [routePoints, setRoutePoints] = React.useState([]);
  const [toclone, setToclone] = React.useState('');

  const handleAddPoint = (latlng, index, middle) => {
    if (index !== undefined) {
      setRoutePoints(prevState => {
        const dummy = [...prevState];
        if (middle) {
          dummy.splice(index, 0, [latlng.lat, latlng.lng]);
        } else {
          dummy[index] = [latlng.lat, latlng.lng];
        }
        return dummy;
      });
    } else {
      setRoutePoints(prevState => [...prevState, [latlng.lat, latlng.lng]]);
    }
  };

  const handleRemovePoint = index => {
    setRoutePoints(prevState => {
      const dummy = [...prevState];
      dummy.splice(index, 1);
      return dummy;
    });
  };

  const handleClearPoints = () => {
    setMapmode('');
    setRoutePoints([]);
  };

  const handleCloning = () => {
    function doClone() {
      const line = routes.find(x => x.id_route === toclone);
      const decodedRoute = decode(line.polyline || '');
      if (decodedRoute) {
        setRoutePoints(decodedRoute);
      }
      setToclone('');
    }
    /* function doClone() {
      const points = catorce.features[0].geometry.coordinates.map(p => [p[1], p[0]]);
      setRoutePoints(points);
    } */
    if (toclone) {
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            Esta a punto de reemplazar los puntos de línea, se perderan los cambios no guardados.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: doClone,
          },
        ],
      });
    }
  };

  const routeDistance = React.useMemo(() => {
    let distance = 0;
    if (routePoints.length > 1) {
      for (let i = 1; i < routePoints.length; i++) {
        const d = getDistance(
          { latitude: routePoints[i - 1][0], longitude: routePoints[i - 1][1] },
          { latitude: routePoints[i][0], longitude: routePoints[i][1] },
        );
        distance += d;
      }
    }
    return distance;
  }, [routePoints]);

  /** **************************************** */
  /** POIs */
  /** **************************************** */
  const [showPois, setShowPois] = React.useState(true);
  const [pois, setPois] = React.useState([]);
  const [poisList, setPoisList] = React.useState([]);
  const [poiQuery, setPoiQuery] = React.useState('');

  const debouncedPoiQuery = useDebounce(poiQuery, 300);

  React.useEffect(() => {
    async function getPois() {
      loading.set();
      const [res, err] = await of(
        roAPI.get('/pois', {
          params: {
            id_city: city,
            limit: 1000,
          },
        }),
      );
      if (!err) {
        advancedSetPois(res.rows);
      }
      loading.stop();
    }
    getPois();
  }, [city, loading]);

  React.useEffect(() => {
    if (!poiQuery) {
      setPoisList(pois);
    } else if (debouncedPoiQuery === poiQuery) {
      setPoisList(pois.filter(p => p.name.toLowerCase().indexOf(poiQuery.toLocaleLowerCase()) !== -1));
    }
  }, [pois, poiQuery, debouncedPoiQuery]);

  const advancedSetPois = newPois => {
    setPois(prevPois => {
      const filtered = newPois.filter(p => prevPois.findIndex(x => x.id_poi === p.id_poi) === -1);
      const newArr = [...prevPois, ...filtered];
      newArr.sort((a, b) => parseInt(a.id_poi) - parseInt(b.id_poi));
      return newArr;
    });
  };

  const togglePOIsVisibility = () => setShowPois(prev => !prev);

  const handlePOIClick = id => {
    setPois(prevPOIs => {
      const dummy = [...prevPOIs];
      const index = dummy.findIndex(p => p.id_poi === id);
      dummy[index] = {
        ...dummy[index],
        selected: !dummy[index].selected,
      };
      return dummy;
    });
  };

  /** **************************************** */
  /** Render */
  /** **************************************** */

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <button type="button" className={`btn btn-link ${styles['back']}`} onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
            <span>Regresar</span>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="row">
            <div className="col-12">
              <ul className={`nav nav-pills ${styles['tabs']}`}>
                <li className="nav-item">
                  <button
                    className={`nav-link btn btn-link ${tabs === 'info' && 'active'}`}
                    title="Información"
                    onClick={() => setTabs('info')}
                  >
                    <FontAwesomeIcon icon={faFileAlt} />
                    {tabs !== 'info' && !valid.info && <div className={styles['error-badge']} />}
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link btn btn-link ${tabs === 'times' && 'active'}`}
                    title="Tiempos"
                    onClick={() => setTabs('times')}
                  >
                    <FontAwesomeIcon icon={faClock} />
                    {tabs !== 'times' && !valid.times && <div className={styles['error-badge']} />}
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link btn btn-link ${tabs === 'style' && 'active'}`}
                    title="Personalización"
                    onClick={() => setTabs('style')}
                  >
                    <FontAwesomeIcon icon={faSlidersH} />
                    {tabs !== 'style' && !valid.style && <div className={styles['error-badge']} />}
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link btn btn-link ${tabs === 'poly' && 'active'}`}
                    title="Coordenadas"
                    onClick={() => setTabs('poly')}
                  >
                    <FontAwesomeIcon icon={faRoute} />
                  </button>
                </li>
              </ul>
            </div>

            <div className="col-12" style={{ ...(tabs === 'poly' && { display: 'none' }) }}>
              <FormLine
                onSubmit={submitLine}
                initialValues={line}
                cities={formCities}
                onCityChange={({ target: { value } }) => changeCity(value)}
                onParentChange={({ target: { value } }) => handleParentChange(value)}
                tabs={tabs}
                routes={routes}
                loading={loading}
                updateRoutes={getRoutes}
              />
            </div>

            <div className="col-12" style={{ ...(tabs !== 'poly' && { display: 'none' }) }}>
              {line.id_parent_route && (
                <div className="row">
                  <div className="col-12">
                    <label className="label">Clonar línea (puntos)</label>
                    <div className="input-group mb-3">
                      <select
                        className="form-control"
                        name="clonar"
                        value={toclone}
                        onChange={({ target: { value } }) => setToclone(value)}
                      >
                        <option value="" disabled>
                          Seleccione línea a clonar
                        </option>
                        {routes.map(option => (
                          <option key={option.id_route} value={option.id_route}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="button" onClick={handleCloning}>
                          <FontAwesomeIcon icon={faCopy} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={`btn-group ${styles['buttons']}`} role="group" aria-label="Basic example">
                <button
                  type="button"
                  className={`btn btn-primary ${mapMode === 'draw' && 'active'}`}
                  onClick={() => handleMapmode('draw')}
                >
                  {mapMode === 'draw' ? 'Terminar' : 'Crear'}
                </button>
                <button
                  type="button"
                  className={`btn btn-primary ${mapMode === 'edit' && 'active'}`}
                  onClick={() => handleMapmode('edit')}
                >
                  {mapMode === 'edit' ? 'Terminar' : 'Editar'}
                </button>
                <button type="button" className="btn btn-primary" onClick={handleClearPoints}>
                  Limpiar
                </button>
              </div>
              <dl className={`row ${styles['dl']}`}>
                <dt className="col-sm-8">Número de puntos</dt>
                <dd className="col-sm-4">{routePoints.length}</dd>
                <dt className="col-sm-8">Distancia estimada</dt>
                <dd className="col-sm-4">{routeDistance / 1000} km</dd>
              </dl>
              <div className="row">
                <div className="col-12">
                  {line.id_parent_route && (
                    <div className={styles['disabled']}>
                      <p>Los puntos de interes se manejan desde la línea padre.</p>
                    </div>
                  )}
                  <h5>Puntos de interes</h5>
                  <div className={`input-group mb-3 ${styles['poi-list-btn-group']}`}>
                    <div className="input-group-prepend">
                      <button className="btn btn-primary" type="button" onClick={togglePOIsVisibility}>
                        <FontAwesomeIcon icon={showPois ? faToggleOn : faToggleOff} />
                      </button>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Filtrar POIs por nombre"
                      value={poiQuery}
                      onChange={({ target: { value } }) => setPoiQuery(value)}
                    />
                  </div>
                  <p className={styles['poi-list-desc']}>
                    {pois.length === poisList.length
                      ? `Mostrando ${poisList.length} POIs`
                      : `Mostrando ${poisList.length} de ${pois.length} POIs`}
                  </p>
                  <ul className={styles['poi-list']}>
                    {poisList.map(p => {
                      return (
                        <li key={p.id_poi} onClick={() => handlePOIClick(p.id_poi)}>
                          <span>
                            <strong>{p.name}</strong>
                          </span>
                          <FontAwesomeIcon icon={p.selected ? faCheckSquare : faSquare} />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className={styles['map']}>
            <LeafletLinesMap
              cities={cities}
              city={city}
              mapMode={mapMode}
              route={routePoints}
              addPoint={handleAddPoint}
              removePoint={handleRemovePoint}
              pois={pois}
              showPois={showPois}
            />
          </div>
          {mapMode === 'edit' && (
            <p className={styles['message']}>En modo edición: doble clic sobre un punto para eliminarlo.</p>
          )}
        </div>
      </div>
      <div className="row" style={{ marginTop: '1.5rem' }}>
        {/** Submit buttons */}
        <div className="col-12">
          {line.group_id && (
            <button
              type="button"
              className="btn btn-link btn-link-eliminar"
              onClick={() => handleOnDelete(line.id_route)}
            >
              Eliminar línea
            </button>
          )}
          <button type="submit" form="formLine" className="btn btn-une">
            Guardar cambios
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

NewLine.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.any,
  }),
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
  cities: PropTypes.array,
  city: PropTypes.string,
  changeCity: PropTypes.func,
  routes: PropTypes.array,
  updateRoutes: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  pushAlert: PropTypes.func,
};

NewLine.defaultProps = {
  history: {
    goBack: f => f,
    push: f => f,
  },
  match: {
    params: {},
  },
  location: {
    state: {},
  },
  cities: [],
  city: '',
  changeCity: f => f,
  routes: [],
  updateRoutes: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
  pushAlert: f => f,
};

export default reduxConnect(
  state => ({
    cities: state.cities,
    city: state.city,
    routes: state.routes,
  }),
  dispatch => ({
    updateRoutes: (routes, filter) => dispatch(actions.updateRoutes(routes, filter)),
    changeCity: city => dispatch(actions.changeCity(city)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
    pushAlert: alert => dispatch(actions.alert(alert)),
  }),
)(NewLine);
