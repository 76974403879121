import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import TableUsers from '#components/tables/users/tableUsers';
import User from './user';

const Users = ({ match }) => {
  return (
    <div data-testid="Notificaciones">
      <div className="section-header">
        <h2 className="title">Control de usuarios</h2>
      </div>

      <div className="card">
        <div className="card-body">
          <Switch>
            <Route exact path={`${match.url}`} component={TableUsers} />
            <Route exact path={`${match.url}/user`} component={User} />
            <Route exact path={`${match.url}/user/:id`} component={User} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

Users.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Users.defaultProps = {
  match: {},
};

export default Users;
