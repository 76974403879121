import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MultiSelect from '#components/forms/customForms/multiSelect/multiSelect';
import DatetimePicker from '#components/forms/customForms/datetimePicker/datetimePicker';
import Input from '../../custom/Input';
import Select from '../../custom/Select';
import TextArea from '../../custom/TextArea';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { roAPI } from '#utils/axiosAPI';

const FormNotification = ({ history, cities }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async values => {
    try {
      const send_date = values.send_date ? moment(values.send_date, 'YYYY-MM-DD:HH:mm').toISOString() : null;
      const level = values.level === 'linea' ? 'importante' : values.level;
      const topic = values.city.map(item => `avisos_${item.id_city}_${level}`).join(',');
      await roAPI.post('/notifications', {
        title: values.title,
        message: values.message,
        topic,
        ...(send_date && { send_date }),
      });
      history.push('/panel/notificaciones');
    } catch (error) {
      console.error(error);
    }
  };

  const topics = React.useMemo(() => {
    const allowed = process.env.REACT_APP_PUSH === 'true';
    return allowed
      ? [
          { display: 'Aviso general', value: 'general' },
          { display: 'Aviso importante', value: 'importante' },
          { display: 'Aviso de línea', value: 'linea' },
          { display: 'Encuesta general', value: 'encuesta' },
          { display: 'Encuesta registrados', value: 'encuesta_registrados' },
          { display: 'Pruebas', value: 'prueba' },
        ]
      : [{ display: 'Pruebas', value: 'prueba' }];
  }, []);

  return (
    <div data-testid="FormNotification">
      <div className="row">
        <div className="col-12">
          <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
            <span>Regresar</span>
          </button>
        </div>
      </div>
      <p>Registra una nueva notificación que será enviada a todos los dispositivos que cuenten con la aplicación.</p>

      <form id="formNotification" onSubmit={handleSubmit(onSubmit)}>

        <div className="col-12 col-md-4">
          <Input
            type="text"
            name="title"
            label="Título"
            placeholder="Inserte título"
            {...register('title', { required: 'Este campo es requerido', maxLength: 255 })}
            error={errors.title}
          />
        </div>

        <div className="col-12 col-md-4">
          <MultiSelect
            name="city"
            label="Ciudad"
            placeholder="Seleccione ciudad"
            itemKey="id_city"
            items={cities}
            renderItem={item => item.name}
            renderInput={item => item.name}
            {...register('city', { required: 'Este campo es requerido' })}
            error={errors.city}
          />
        </div>

        <div className="col-12 col-md-4">
          <Select
            name="level"
            label="Tipo de notificación"
            defaultValue=""
            options={topics}
            {...register('level', { required: 'Este campo es requerido' })}
            error={errors.level}
          />
        </div>

        <div className="col-12">
          <TextArea
            name="message"
            label="Mensaje"
            placeholder="Inserte su mensaje"
            {...register('message', { required: 'Este campo es requerido', maxLength: 1024 })}
            error={errors.message}
          />
        </div>

        <div className="col-12">
          <p className="form-separator">Programación (Opcional)</p>
        </div>

        <div className="col-4">
          <DatetimePicker
            name="send_date"
            label="Fecha y hora de envío"
            {...register('send_date')}
          />
        </div>

        <button type="submit" className={`btn btn-une btn-submit btn-submit-center`}>
          Publicar notificación
        </button>

      </form>
    </div>
  );
};

FormNotification.propTypes = {
  history: PropTypes.any,
  cities: PropTypes.array,
};

FormNotification.defaultProps = {
  history: {},
  cities: [],
};

export default FormNotification;
