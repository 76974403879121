import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const WafoDraftJS = ({
  customClass,
  name,
  label,
  value,
  handleInputChange,
  valid,
  touched,
  errors,
  toolbarClassName,
  wrapperClassName,
  editorClassName,
}) => {
  const handleEditorChange = editorState => {
    handleInputChange({
      target: {
        name,
        value: editorState,
      },
      type: 'change',
    });
  };

  return (
    <div className={`wafoformdraftjs form-group wafo-input ${customClass}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <Editor
        editorState={value}
        toolbarClassName={toolbarClassName}
        wrapperClassName={wrapperClassName}
        editorClassName={editorClassName}
        onEditorStateChange={handleEditorChange}
      />
      {!valid && touched && (
        <ul className="errors">
          {errors.map(error => (
            <li key={error.error}>{error.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

WafoDraftJS.propTypes = {
  customClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  handleInputChange: PropTypes.func,
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.any),
  validations: PropTypes.object,
  // Draft JS
  toolbarClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  editorClassName: PropTypes.string,
};

WafoDraftJS.defaultProps = {
  customClass: '',
  label: undefined,
  value: '',
  handleInputChange: f => f,
  valid: false,
  touched: false,
  errors: [],
  validations: {},
  toolbarClassName: 'draftjsToolbarWrapper',
  wrapperClassName: 'draftjsWrapper',
  editorClassName: 'draftjsEditorWrapper',
};

export default WafoDraftJS;
