import React from 'react';
import PropTypes from 'prop-types';

const Select = React.forwardRef(({ label, placeholder, options, error, ...selectProps }, ref) => {
  return (
    <div className="mb-4 flex flex-col">
      {label && <label className="block font-neue-haas-grotesk text-xl font-medium mb-1 text-black">{label}</label>}
      <select
        ref={ref}
        className={`w-full border ${error ? 'border-red-500' : 'border-gray-300'} hover:border-system1 rounded p-2 transition bg-white font-neue-haas-grotesk text-lg`}
        {...selectProps}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <p className="mt-2 text-xs text-red-500 font-neue-haas-grotesk">{error.message}</p>}
    </div>
  );
});

Select.displayName = 'Select';

Select.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  error: PropTypes.object,
};

export default Select;
