import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from '#components/forms/customForms/timePicker/timePicker';
import styles from './credentials.module.css';
import SelectCoordinateMap from '#components/maps/selectCoordinateMap/selectCoordinateMap';
import useUpdateCities from '#hooks/useUpdateCities';
import ImagePicker from '#components/forms/customForms/imagePicker/imagePicker';
import Input from '../../custom/Input';
import Select from '../../custom/Select';
import TextArea from '../../custom/TextArea';
import { useForm } from 'react-hook-form';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { roAPI } from '#utils/axiosAPI';

const FormRechargePoint = ({
  history,
  match: { params },
  location,
  cities,
  city,
  updateCities,
  changeCity,
  loading,
}) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [rp, setRp] = React.useState(() => {
    const initialRP = {
      address: '',
      latlng: '',
    };
    if (location.state && location.state.rp) {
      return {
        ...initialRP,
        ...location.state.rp,
        ...location.state.rp.attention_hours,
        latlng: `${location.state.rp.latitude},${location.state.rp.longitude}`,
      };
    }
    return initialRP;
  });

  React.useEffect(() => {
    if (location.state && location.state.rp) {
      changeCity(location.state.rp.id_city);
    }
  }, [location.state, changeCity]);

  useUpdateCities(updateCities, loading, false);

  const onSubmit = async values => {
    try {
      loading.set();
      const newRP = {
        id_city: values.id_city,
        type: values.type,
        name: values.name,
        description: values.description,
        address: rp.address,
        ...(values.telephone && { telephone: values.telephone }),
        latitude: rp.latlng.split(',')[0],
        longitude: rp.latlng.split(',')[1],
        attention_hours: {
          service_start: values.service_start,
          service_end: values.service_end,
          service_start_saturday: values.service_start_saturday,
          service_end_saturday: values.service_end_saturday,
          service_start_sunday: values.service_start_sunday,
          service_end_sunday: values.service_end_sunday,
        },
      };
      const res = params.id ? await roAPI.put(`/rps/${params.id}`, newRP) : await roAPI.post('/rps', newRP);
      setRp(prevRP => ({ ...prevRP, ...res }));
      if (values.image && typeof values.image !== 'string') {
        const formModel = new FormData();
        formModel.append('image', values.image);
        await roAPI.post(`/rps/${res.id_rp}/image`, formModel);
      }
      history.push('/panel/credencializacion');
      loading.stop();
    } catch (error) {
      loading.stop();
      console.error(error);
    }
  };

  const handleMarkerAccept = React.useCallback(result => {
    if (result.addresses.length) {
      setRp(prevRP => ({
        ...prevRP,
        address: result.addresses[0].address.freeformAddress,
        latlng: `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`,
      }));
    } else {
      setRp(prevRP => ({
        ...prevRP,
        latlng: `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`,
      }));
    }
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
          <span>Regresar</span>
        </button>
      </div>
      <div className="col-12">
        <form id="formRP" locale="es" onSubmit={handleSubmit(onSubmit)} values={rp}>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6">
                <Select
                  name="id_city"
                  label="Ciudad"
                  defaultValue=""
                  options={cities.map(x => ({
                    value: x.id_city,
                    label: x.name,
                  }))}
                  {...register('id_city', { required: 'Este campo es requerido' })}
                  error={errors.id_city}
                  onChange={({ target: { value } }) => changeCity(value)}
                />
              </div>

              <div className="col-6">
                <Select
                  name="type"
                  label="Tipo de ubicación"
                  defaultValue=""
                  options={[
                    { value: 1, label: 'Punto de recarga' },
                    { value: 2, label: 'Centro credencialización' },
                  ]}
                  {...register('type', { required: 'Este campo es requerido' })}
                  error={errors.type}
                />
              </div>

              <div className="col-6">
                <Input
                  name="name"
                  label="Nombre"
                  placeholder="Nombre"
                  {...register('name', {
                    required: 'Este campo es requerido',
                    pattern: {
                      value: /^[a-zA-Z0-9._ ]+$/,
                      message: 'Solo caracteres válidos',
                    },
                    maxLength: 254,
                  })}
                  error={errors.name}
                />
              </div>

              <div className="col-6">
                <Input
                  name="telephone"
                  label="Teléfono"
                  placeholder="Teléfono"
                  {...register('telephone', {
                    pattern: {
                      value: /^\d{10}$/,
                      message: 'Solo números a 10 digitos',
                    },
                  })}
                  error={errors.telephone}
                />
              </div>

              <div className="col-12">
                <TextArea
                  name="description"
                  label="Descripción"
                  placeholder="Descripción del lugar, señas particulares, etc."
                  {...register('description', {
                    required: 'Este campo es requerido',
                    pattern: {
                      value: /^[a-zA-Z0-9._ ]+$/,
                      message: 'Solo caracteres válidos',
                    },
                    maxLength: 254,
                  })}
                  error={errors.description}
                  rows={4}
                />
              </div>

              <div className="col-12">
                <p className={styles['horas']}>Horarios de atención</p>
              </div>

              <div className="col-4">
                <TimePicker
                  name="service_start"
                  label="Inicio lunes - viernes"
                  {...register('service_start', {
                    required: 'Este campo es requerido',
                    pattern: {
                      value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                      message: 'Hora invalida',
                    },
                  })}
                  error={errors.service_start}
                />
              </div>

              <div className="col-4">
                <TimePicker
                  name="service_end"
                  label="Fin lunes - viernes"
                  {...register('service_end', {
                    required: 'Este campo es requerido',
                    pattern: {
                      value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                      message: 'Hora invalida',
                    },
                  })}
                  error={errors.service_end}
                />
              </div>

              <div className="col-12" />

              <div className="col-4">
                <TimePicker
                  name="service_start_saturday"
                  label="Inicio sábado"
                  {...register('service_start_saturday', {
                    required: 'Este campo es requerido',
                    pattern: {
                      value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                      message: 'Hora invalida',
                    },
                  })}
                  error={errors.service_start_saturday}
                />
              </div>

              <div className="col-4">
                <TimePicker
                  name="service_end_saturday"
                  label="Fin sábado"
                  {...register('service_end_saturday', {
                    required: 'Este campo es requerido',
                    pattern: {
                      value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                      message: 'Hora invalida',
                    },
                  })}
                  error={errors.service_end_saturday}
                />
              </div>

              <div className="col-12" />

              <div className="col-4">
                <TimePicker
                  name="service_start_sunday"
                  label="Inicio domingo"
                  {...register('service_start_sunday', {
                    required: 'Este campo es requerido',
                    pattern: {
                      value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                      message: 'Hora invalida',
                    },
                  })}
                  error={errors.service_start_sunday}
                />
              </div>

              <div className="col-4">
                <TimePicker
                  name="service_end_sunday"
                  label="Fin domingo"
                  {...register('service_end_sunday', {
                    required: 'Este campo es requerido',
                    pattern: {
                      value: /^[0-9]{2}[:]{1}[0-9]{2}([:]{1}[0-9]{2})?$/,
                      message: 'Hora invalida',
                    },
                  })}
                  error={errors.service_end_sunday}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12">
                <label className="label">Ubicación</label>
                <SelectCoordinateMap
                  key={city}
                  showButtons={false}
                  onAccept={handleMarkerAccept}
                  cities={cities}
                  city={city}
                  initialPos={rp.latlng ? [rp.latlng.split(',')[0], rp.latlng.split(',')[1]] : null}
                  poi
                  showShadow={false}
                />
              </div>

              <div className="col-12">
                <Input
                  name="address"
                  label="Dirección"
                  placeholder="Calle, colonia, C.P."
                  {...register('address')}
                  value={rp.address}
                  disabled
                  style={{ display: 'none' }}
                />
              </div>

              <div className="col-12">
                <Input
                  name="latlng"
                  label="Latitud y Longitud"
                  placeholder="0, 0"
                  {...register('latlng', {
                    required: {
                      value: true,
                      message: 'Campo requerido. Marca la ubicación en el mapa.',
                    },
                  })}
                  value={rp.latlng}
                  disabled
                  style={{ display: 'none' }}
                />
              </div>

              <div className="col-6">
                <ImagePicker
                  name="image"
                  label="Imagen de RP"
                  customClass="col-12"
                  serverBasePath={process.env.REACT_APP_IMG_RP}
                  handleChange
                />
              </div>
            </div>
          </div>
        </form>
        <button type="submit" form="formRP" className={`btn btn-une btn-submit btn-submit-center`}>
          Guardar cambios
        </button>
      </div>
    </div>
  );
};

FormRechargePoint.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.any,
  }),
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
  cities: PropTypes.array,
  updateCities: PropTypes.func,
  city: PropTypes.string,
  changeCity: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

FormRechargePoint.defaultProps = {
  history: {
    goBack: f => f,
    push: f => f,
  },
  match: {
    params: {},
  },
  location: {
    state: {},
  },
  cities: [],
  updateCities: f => f,
  city: '1',
  changeCity: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  state => ({
    cities: state.cities,
    city: state.city,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    changeCity: city => dispatch(actions.changeCity(city)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(FormRechargePoint);
