import React from 'react';
import { useDispatch } from 'react-redux';

const useLoading = () => {
  const dispatch = useDispatch();

  const loading = React.useMemo(
    () => ({
      set: () => dispatch({ type: 'LOADING_ADD' }),
      stop: () => dispatch({ type: 'LOADING_REMOVE' }),
    }),
    [dispatch],
  );

  return loading;
};

export default useLoading;
