import React from 'react';
import PropTypes from 'prop-types';
import SelectCoordinateMap from '#components/maps/selectCoordinateMap/selectCoordinateMap';
import useUpdateCities from '#hooks/useUpdateCities';
import ImagePicker from '#components/forms/customForms/imagePicker/imagePicker';
import Input from '../../custom/Input';
import Select from '../../custom/Select';
import TextArea from '../../custom/TextArea';
import { useForm } from 'react-hook-form';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { roAPI } from '#utils/axiosAPI';

const FormPoi = ({ history, match: { params }, location, cities, city, updateCities, changeCity, loading }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [poi, setPoi] = React.useState(() => {
    const initialPOI = {
      address: '',
      latlng: '',
    };
    if (location.state && location.state.poi) {
      return {
        ...initialPOI,
        ...location.state.poi,
        latlng: `${location.state.poi.latitude},${location.state.poi.longitude}`,
      };
    }
    return initialPOI;
  });

  React.useEffect(() => {
    if (location.state && location.state.poi) {
      changeCity(location.state.poi.id_city);
    }
  }, [location.state, changeCity]);

  useUpdateCities(updateCities, loading, false);

  const onSubmit = async values => {
    try {
      loading.set();
      const newPOI = {
        id_city: values.id_city,
        name: values.name,
        description: values.description,
        address: poi.address,
        latitude: poi.latlng.split(',')[0],
        longitude: poi.latlng.split(',')[1],
      };
      const res = params.id ? await roAPI.put(`/pois/${params.id}`, newPOI) : await roAPI.post('/pois', newPOI);
      setPoi(prevPOI => ({ ...prevPOI, ...res }));
      if (values.image && typeof values.image !== 'string') {
        const formModel = new FormData();
        formModel.append('image', values.image);
        await roAPI.post(`/pois/${res.id_poi}/image`, formModel);
      }
      history.push('/panel/pois');
      loading.stop();
    } catch (error) {
      loading.stop();
      console.error(error);
    }
  };

  const handleMarkerAccept = React.useCallback(result => {
    if (result.addresses.length) {
      setPoi(prevPOI => ({
        ...prevPOI,
        address: result.addresses[0].address.freeformAddress,
        latlng: `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`,
      }));
    } else {
      setPoi(prevPOI => ({
        ...prevPOI,
        latlng: `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`,
      }));
    }
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
          <span>Regresar</span>
        </button>
      </div>
      <div className="col-12">
        <form id="formPoi" locale="es" onSubmit={handleSubmit(onSubmit)} values={poi}>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12">
                <Select
                  name="id_city"
                  label="Ciudad"
                  defaultValue=""
                  options={cities.map(x => ({
                    value: x.id_city,
                    label: x.name,
                  }))}
                  {...register('id_city', { required: 'Este campo es requerido' })}
                  error={errors.id_city}
                  onChange={({ target: { value } }) => changeCity(value)}
                />
              </div>

              <div className="col-12">
                <Input
                  name="name"
                  label="Nombre"
                  placeholder="Nombre"
                  {...register('name', {
                    required: 'Este campo es requerido',
                    maxLength: 254,
                  })}
                  error={errors.name}
                />
              </div>

              <div className="col-12">
                <TextArea
                  name="description"
                  label="Descripción"
                  placeholder="Descripción del lugar, señas particulares, etc."
                  {...register('description', {
                    required: 'Este campo es requerido',
                    maxLength: 254,
                  })}
                  error={errors.description}
                  rows={4}
                />
              </div>

              <div className="col-6">
                <ImagePicker
                  name="image"
                  label="Imagen de POI"
                  serverBasePath={process.env.REACT_APP_IMG_POIS}
                  {...register('image')}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12">
                <label className="label">Ubicación</label>
                <SelectCoordinateMap
                  key={city}
                  showButtons={false}
                  onAccept={handleMarkerAccept}
                  cities={cities}
                  city={city}
                  initialPos={poi.latlng ? [poi.latlng.split(',')[0], poi.latlng.split(',')[1]] : null}
                  poi
                  showShadow={false}
                />
              </div>

              <div className="col-12">
                <Input
                  name="address"
                  label="Dirección"
                  placeholder="Calle, colonia, C.P."
                  {...register('address')}
                  value={poi.address}
                  disabled
                  style={{ display: 'none' }}
                />
              </div>

              <div className="col-12">
                <Input
                  name="latlng"
                  label="Latitud y Longitud"
                  placeholder="0, 0"
                  {...register('latlng', {
                    required: 'Campo requerido. Marca la ubicación en el mapa.',
                  })}
                  value={poi.latlng}
                  disabled
                  style={{ display: 'none' }}
                />
              </div>
            </div>
          </div>
        </form>
        <button type="submit" form="formPoi" className={`btn btn-une btn-submit btn-submit-center`}>
          Guardar cambios
        </button>
      </div>
    </div>
  );
};

FormPoi.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.any,
  }),
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
  cities: PropTypes.array,
  updateCities: PropTypes.func,
  city: PropTypes.string,
  changeCity: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

FormPoi.defaultProps = {
  history: {
    goBack: f => f,
    push: f => f,
  },
  match: {
    params: {},
  },
  location: {
    state: {},
  },
  cities: [],
  updateCities: f => f,
  city: '1',
  changeCity: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  state => ({
    cities: state.cities,
    city: state.city,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    changeCity: city => dispatch(actions.changeCity(city)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(FormPoi);
