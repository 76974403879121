import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import { DataTable } from '@wafo/table';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/locale/es';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import confirmModal from '../../modals/confirm/confirm';

const TablePois = ({ loading }) => {
  const [rawRows, setRawRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const getData = React.useCallback(async event => {
    const { size, page, search } = event;
    const [resp, error] = await of(
      roAPI.get('/pois', {
        params: {
          limit: size,
          page,
          nocache: true,
          ...(search && { search }),
        },
      }),
    );
    if (!error) {
      setRawRows(resp.rows);
      setTotalRows(parseInt(resp.count, 10));
    }
  }, []);

  const clickReload = (id = 'wafotable-reload') => {
    const btn = document.getElementById(id);
    btn.click();
  };

  const handleDelete = React.useCallback(
    id => {
      async function deletePOI() {
        loading.set();
        const [, err] = await of(roAPI.delete(`/pois/${id}`));
        if (!err) {
          clickReload();
        }
        loading.stop();
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            También será eliminado de las líneas donde aparece.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: deletePOI,
          },
        ],
      });
    },
    [loading],
  );

  const rows = rawRows.map(row => ({
    id: row.id_poi,
    name: row.name,
    address: row.address,
    date: row.updated_at,
    options: { id: row.id_poi, latlng: [row.latitude, row.longitude], poi: row },
  }));

  const columnsConfig = React.useMemo(
    () => ({
      date: val => <span> {moment(val).format('DD MMMM YYYY HH:mm')}</span>,
      options: {
        render: function col(val) {
          return (
            <>
              <Link
                className="btn btn-sm btn-primary"
                to={{ pathname: `/panel/pois/poi/${val.id}`, state: { poi: val.poi } }}
                style={{ marginRight: '.25rem' }}
              >
                <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                <span>Editar</span>
              </Link>
              <button type="button" className="btn btn-sm btn-danger" onClick={() => handleDelete(val.id)}>
                <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '.5rem' }} />
                Eliminar
              </button>
            </>
          );
        },
        style: {
          whiteSpace: 'nowrap',
        },
      },
    }),
    [handleDelete],
  );

  return (
    <div>
      <DataTable
        locale="es"
        columns={['ID', 'Nombre', 'Dirección', 'Última actualización', 'Opciones']}
        rows={rows}
        totalRows={totalRows}
        onPagination={getData}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        noRowsMessage="Sin puntos de interés"
      />
    </div>
  );
};

TablePois.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

TablePois.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TablePois);
