import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import LeafletStopsMap from '#components/maps/stopsMap/stopsMap';
import useUpdateCities from '#hooks/useUpdateCities';
import confirmModal from '#components/modals/confirm/confirm';
import styles from './stopsMap.module.css';
import Input from '#components/custom/Input';
import Select from '#components/custom/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faPlus, faCompressArrowsAlt, faSyncAlt, faUndoAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { getDistance } from 'geolib';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';

const StopsMap = ({ cities, city, routes, changeCity, updateRoutes, updateCities, loading }) => {
  const [route, setRoute] = React.useState('');
  const [routeObj, setRouteObj] = React.useState({});
  const [stops, setStops] = React.useState({});
  const [stop, setStop] = React.useState({});
  const [collisions, setCollisions] = React.useState({});
  const [collisionThreshold, setCollisionThreshold] = React.useState(5);

  useUpdateCities(updateCities, loading, false);

  React.useEffect(() => {
    async function fetchRoutes() {
      loading.set();
      const [res] = await of(roAPI.get(`/routes/city/${city}`));
      updateRoutes(res);
      loading.stop();
    }
    async function fetchStops() {
      loading.set();
      const [res, err] = await of(
        roAPI.get(`/stops/${city}`, {
          params: {
            nocache: true,
          },
        }),
      );
      if (!err) {
        Object.keys(res).forEach(line => {
          res[line] = res[line].map(s => ({
            ...s,
            linea: typeof s.linea === 'string' ? s.linea : s.linea.toString(),
            lineas: s.lineas.map(l => (typeof l === 'string' ? l : l.toString())),
          }));
        });
        setStops(res);
      }
      loading.stop();
    }
    Promise.all([fetchRoutes(), fetchStops()]);
    // Cleaning up.
    setRoute('');
    setRouteObj({});
  }, [city, updateRoutes, loading]);

  React.useEffect(() => {
    setStop({});
    setCollisions({});
  }, [city, route]);

  const handleCityChange = ({ target: { value } }) => changeCity(value);

  const handleLineChange = ({ target: { value } }) => {
    setRoute(value);
    const obj = routes.find(x => x.group_id === value);
    setRouteObj(obj);
  };

  const handleStopClick = (e, s) => {
    if (!stop || !stop.new) {
      setStop(s);
    } else if (stop && stop.new) {
      setStop(prevStop => ({
        ...prevStop,
        afterStop: s.codigo,
      }));
    }
  };

  const handleStopDrag = ({ target: { _latlng } }, s) => {
    const newStop = { ...s, latitude: _latlng.lat, longitude: _latlng.lng };
    setStop(newStop);
    if (!s.new) {
      setStops(prevStops => {
        const dummyStops = { ...prevStops };
        s.lineas.forEach(l => {
          const index = dummyStops[l].findIndex(x => x.codigo === s.codigo);
          dummyStops[l][index] = newStop;
        });
        return dummyStops;
      });
    }
  };

  const handleLatLngChange = ({ target: { name, value } }) => {
    const newStop = { ...stop, [name]: value };
    setStop(newStop);
    if (!stop.new) {
      setStops(prevStops => {
        const dummyStops = { ...prevStops };
        stop.lineas.forEach(l => {
          const index = dummyStops[l].findIndex(x => x.codigo === stop.codigo);
          dummyStops[l][index] = newStop;
        });
        return dummyStops;
      });
    }
  };

  const handleNewStop = () => {
    setCollisions({});
    // const l = parseInt(route, 10);
    const l = route;
    const codigo = stops[route] ? `${route}-${stops[route].length + 1}` : `${route}-1`;
    setStop({
      new: true,
      codigo,
      linea: l,
      name: '',
      latitude: 0,
      longitude: 0,
      lineas: [l],
    });
  };

  const handleNewStopLineToggle = group_id => {
    const gid = group_id;
    if (stop.lineas.includes(gid)) {
      setStop(prevStop => {
        const dummy = [...prevStop.lineas];
        const index = dummy.indexOf(gid);
        dummy.splice(index, 1);
        return {
          ...prevStop,
          lineas: dummy,
        };
      });
    } else {
      // add to array
      setStop(prevStop => ({ ...prevStop, lineas: [...prevStop.lineas, gid] }));
    }
  };

  const saveNewStop = () => {
    setStops(prevStops => {
      const newStop = { ...stop };
      delete newStop.new;
      delete newStop.afterStop;
      const dummyStops = prevStops ? { ...prevStops } : {};
      stop.lineas.forEach(l => {
        if (dummyStops[l]) {
          const index = dummyStops[l].findIndex(x => x.codigo === stop.afterStop);
          dummyStops[l].splice(index + 1, 0, newStop);
          // dummyStops[l].push(newStop);
        } else {
          dummyStops[l] = [newStop];
        }
      });
      return dummyStops;
    });
    setStop({});
  };

  const handleDeleteStop = () => {
    setStops(prevStops => {
      const dummyStops = { ...prevStops };
      stop.lineas.forEach(l => {
        const index = dummyStops[l].findIndex(x => x.codigo === stop.codigo);
        dummyStops[l].splice(index, 1);
      });
      return dummyStops;
    });
    setStop({});
  };

  // handles on map click for diferent functions.
  const handleMapClick = ({ latlng }) => {
    if (stop.new && (!stop.latitude || !stop.longitude)) {
      // If new stop and no latlng
      setStop(prevStop => ({
        ...prevStop,
        latitude: latlng.lat,
        longitude: latlng.lng,
      }));
    }
  };

  const checkCollisions = () => {
    if (Object.keys(stops).length && route) {
      loading.set();
      const collisions = {};
      Object.values(stops)
        .flat()
        //.filter(x => x.linea !== route)
        .filter(x => !x.lineas.includes(route))
        .forEach(stop => {
          stops[route].forEach(lstop => {
            const distance = getDistance(
              { latitude: stop.latitude, longitude: stop.longitude },
              { latitude: lstop.latitude, longitude: lstop.longitude },
            );
            if (distance < collisionThreshold) {
              if (collisions[lstop.codigo]) {
                collisions[lstop.codigo].push(stop);
              } else {
                collisions[lstop.codigo] = [stop];
              }
            }
          });
        });
      setCollisions(collisions);
      setStop({});
      loading.stop();
    }
  };

  // Empties collisions object so the UI shows the normal stops.
  const outOfCollisions = () => {
    setCollisions({});
    setStop({});
  };

  // Select lines where the stop will be replaced
  const toggleReplace = index => {
    setStop(prevState => {
      const collisions = prevState.collisions;
      collisions[index].replace = !collisions[index].replace;
      return {
        ...prevState,
        collisions,
      };
    });
  };

  const handleMerge = () => {
    if (!stop.collisions.length) {
      return;
    }
    const toMerge = [stop, ...stop.collisions.filter(s => s.replace)];
    if (toMerge.length) {
      const baseStop = {
        ...stop,
        lineas: [...new Set([...stop.lineas, ...toMerge.map(x => x.linea)])],
      };
      delete baseStop.collisions;
      setStops(prevStops => {
        const dummy = { ...prevStops };
        toMerge.forEach(c => {
          const index = dummy[c.linea].findIndex(s => s.codigo === c.codigo);
          dummy[c.linea][index] = baseStop;
        });
        return dummy;
      });
      setCollisions(prevState => {
        const lmao = prevState[stop.codigo].filter(s => !toMerge.find(x => x.codigo === s.codigo));
        return {
          ...prevState,
          [stop.codigo]: lmao,
        };
      });
    }
  };

  const lineStops = React.useMemo(() => {
    if (Object.prototype.hasOwnProperty.call(stops, route)) {
      if (Object.keys(collisions).length) {
        //return Object.values(stops[route])
        return stops[route]
          .filter(s => collisions[s.codigo])
          .map(s => ({
            ...s,
            collisions: collisions[s.codigo],
          }));
      }
      // return Object.values(stops[route]);
      return stops[route];
    }
    return [];
  }, [route, stops, collisions]);

  React.useEffect(() => {
    if (stop.codigo && collisions[stop.codigo]) {
      const s = lineStops.find(x => x.codigo === stop.codigo);
      if (s) {
        setStop(s);
      }
    }
  }, [collisions, lineStops, stop]);

  // Sends the stops array from state to the server.
  const serverSync = () => {
    const sync = async () => {
      loading.set();
      const [res, err] = await of(roAPI.post(`/stops/${city}`, stops));
      if (!err) {
        setStops(res);
      }
      loading.stop();
    };
    confirmModal({
      message: (
        <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
          Sincronizar es irreversible, se reiniciara el cache y los cambios se reflejaran inmediatamente en la aplicación.
          <br />
          ¿Desea continuar?
        </p>
      ),
      buttons: [
        {
          label: 'Cancelar',
          class: 'btn-secondary',
        },
        {
          label: 'Aceptar',
          class: 'btn-une',
          onClick: sync,
        },
      ],
    });
  };

  // Gets stops array from the server and overrides the one in the state.
  const cancelChanges = () => {
    async function revert() {
      loading.set();
      const [res, err] = await of(
        roAPI.get(`/stops/${city}`, {
          params: {
            nocache: true,
          },
        }),
      );
      if (!err) {
        Object.keys(res).forEach(line => {
          res[line] = res[line].map(s => ({
            ...s,
            linea: typeof s.linea === 'string' ? s.linea : s.linea.toString(),
            lineas: s.lineas.map(l => (typeof l === 'string' ? l : l.toString())),
          }));
        });
        setStops(res);
        setCollisions({});
        setStop({});
      }
      loading.stop();
    }
    confirmModal({
      message: '¿Revertir cambios actuales?',
      buttons: [
        {
          label: 'Cancelar',
          class: 'btn-secondary',
        },
        {
          label: 'Aceptar',
          class: 'btn-une',
          onClick: revert,
        },
      ],
    });
  };

  // To disable or not the add new Stop button, based on some conditions.
  const disableNewStop = React.useMemo(() => {
    if (stop.new && (stop.latitude && stop.longitude) && (!stops[stop.linea] || stops[stop.linea].length === 0 || stop.afterStop)) {
      return false;
    }
    return true;
  }, [stop, stops]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <div className="row align-items-end">
            <Select
              name="city"
              customClass="col-3"
              label="Ciudad"
              placeholder="Selecciona la ciudad"
              options={cities.map(x => ({ value: x.id_city, label: x.name }))}
              value={city.toString()}
              onChange={handleCityChange}
            />
  
            <Select
              name="route"
              customClass="col-3"
              label="Línea"
              placeholder="Selecciona línea"
              options={routes
                .filter(x => x.group_id && x.group_id !== -1)
                .map(x => ({
                  value: x.group_id,
                  label: x.name,
                }))}
              value={route}
              onChange={handleLineChange}
            />
  
            <div className={`col-6 ${styles['buttons']}`}>
              <div className={styles['collisions']}>
                <input
                  type="number"
                  className="form-control"
                  name="meters"
                  placeholder="0"
                  min={0}
                  max={100}
                  value={collisionThreshold}
                  onChange={({ target: { value } }) => setCollisionThreshold(value)}
                />
                <input className={`form-control ${styles['metros']}`} value="metros" readOnly />
                {Object.keys(collisions).length === 0 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    title="Comprobar colisiones"
                    onClick={checkCollisions}
                    disabled={!route}
                  >
                    <FontAwesomeIcon icon={faCompressArrowsAlt} />
                  </button>
                )}
                {Object.keys(collisions).length > 0 && (
                  <button type="button" className="btn btn-primary" title="Volver a paradas" onClick={outOfCollisions}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </button>
                )}
              </div>
              <button type="button" className="btn btn-primary" onClick={handleNewStop} disabled={!route}>
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '0.5rem' }} />
                <span>Parada</span>
              </button>
              <div className="btn-group" role="group">
                <button type="button" className="btn btn-primary" title="Revertir" onClick={cancelChanges}>
                  <FontAwesomeIcon icon={faUndoAlt} />
                </button>
                <button type="button" className="btn btn-une" title="Sincronizar" onClick={serverSync}>
                  <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '0.5rem' }} />
                  <span>Sincronizar</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="row">
            <div className={`col-12 ${styles['separator']}`}>
              <h5>Información de parada</h5>
            </div>
  
            <Input
              name="codigo"
              customClass="col-12"
              label="Código de parada"
              placeholder="Código"
              value={stop.codigo}
              disabled
            />
  
            <Input
              name="name"
              customClass="col-12"
              label="Nombre"
              placeholder="Parada"
              value={stop.name}
              onChange={({ target: { value } }) => setStop(prevStop => ({ ...prevStop, name: value }))}
              disabled={!stop.new}
            />
  
            <Input
              type="number"
              name="latitude"
              customClass="col-6"
              label="Latitud"
              placeholder="0.00"
              value={stop.latitude}
              onChange={handleLatLngChange}
              disabled={!stop.codigo}
            />
  
            <Input
              type="number"
              name="longitude"
              customClass="col-6"
              label="Longitud"
              placeholder="0.00"
              value={stop.longitude}
              onChange={handleLatLngChange}
              disabled={!stop.codigo}
            />
  
            {stop.new && (
              <Input
                name="afterStop"
                customClass="col-12"
                label="Después de parada"
                placeholder="Código"
                value={stop.afterStop}
                disabled
              >
                <p style={{ margin: '0', color: '#4f4f4f' }}>Da clic sobre una para existente</p>
              </Input>
            )}
  
            {stop.new && (
              <div className={`col-12 ${styles['linelist']}`}>
                <label>Añadir a líneas</label>
                <ul>
                  {routes.map((r, i) => {
                    const selected = stop.lineas.includes(r.group_id);
                    return (
                      <li key={i} className={styles['li-button']} onClick={() => handleNewStopLineToggle(r.group_id)}>
                        <span>
                          <strong>[{r.group_id}]</strong> {r.name}
                        </span>
                        <FontAwesomeIcon icon={selected ? faCheckSquare : faSquare} />
                      </li>
                    );
                  })}
                </ul>
                <div className={styles['buttons-right']}>
                  <button type="button" className="btn btn-link" onClick={() => setStop({})}>
                    Cancelar
                  </button>
                  <button type="button" className="btn btn-une" onClick={saveNewStop} disabled={disableNewStop}>
                    Guardar nueva parada
                  </button>
                </div>
              </div>
            )}
  
            {stop.codigo && !stop.new && (
              <div className={`col-12 ${styles['linelist']}`}>
                <label>Aparece en líneas</label>
                <ul>
                  {stop.lineas.map((l, i) => {
                    const res = routes.find(r => r.group_id === l || r.group_id === l.linea);
                    return <li key={i}>{res ? res.name : 'Undefined'}</li>;
                  })}
                </ul>
              </div>
            )}
  
            {stop.collisions && stop.collisions.length > 0 && (
              <div className={`col-12 ${styles['linelist']}`}>
                <label>Posibles paradas repetidas</label>
                <ul>
                  {stop.collisions.map((l, i) => {
                    const res = routes.find(r => r.group_id === l || r.group_id === l.linea);
                    return (
                      <li key={i} className={styles['li-button']} onClick={() => toggleReplace(i)}>
                        <span>
                          <strong>[{l.codigo}]</strong> {res ? `${res.name}` : 'Undefined'}
                        </span>
                        <FontAwesomeIcon icon={l.replace ? faCheckSquare : faSquare} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
  
            <div className={`col-12 ${styles['buttons-right']}`}>
              {stop.codigo && !stop.new && (
                <button type="button" className="btn btn-link btn-link-eliminar" onClick={handleDeleteStop}>
                  Eliminar
                </button>
              )}
              {stop.collisions && stop.collisions.length > 0 && (
                <button type="button" className="btn btn-une" onClick={handleMerge}>
                  Unificar paradas
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className={styles['map']}>
            <LeafletStopsMap
              cities={cities}
              city={city}
              route={routeObj}
              stops={lineStops}
              selectedStop={stop}
              onStopClick={handleStopClick}
              onStopDrag={handleStopDrag}
              onMapClick={handleMapClick}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

StopsMap.propTypes = {
  cities: PropTypes.array,
  updateCities: PropTypes.func,
  city: PropTypes.string,
  routes: PropTypes.array,
  changeCity: PropTypes.func,
  updateRoutes: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

StopsMap.defaultProps = {
  cities: [],
  updateCities: f => f,
  city: '',
  routes: [],
  changeCity: f => f,
  updateRoutes: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  state => ({
    cities: state.cities,
    city: state.city,
    routes: state.routes,
  }),
  dispatch => ({
    updateRoutes: routes => dispatch(actions.updateRoutes(routes)),
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    changeCity: city => dispatch(actions.changeCity(city)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(StopsMap);
