import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Switch } from 'react-router-dom';

const TabsCard = ({ title, headerButtons, tabs, routes }) => {
  return (
    <div>
      <div className="section-header">
        <h2 className="title">{title}</h2>
        {headerButtons}
      </div>
      <ul className="nav nav-tabs">
        {tabs.map((tab, i) => (
          <li key={i} className="nav-item">
            <NavLink exact={tab.exact} className="nav-link" activeClassName="active" to={tab.to}>
              {tab.render}
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="card">
        <div className="card-body">
          <Switch>{routes}</Switch>
        </div>
      </div>
    </div>
  );
};

TabsCard.propTypes = {
  title: PropTypes.string,
  headerButtons: PropTypes.array,
  tabs: PropTypes.array,
  routes: PropTypes.array,
};

TabsCard.defaultProps = {
  title: 'Título de ejemplo',
  headerButtons: [],
  tabs: [],
  routes: [],
};

export default TabsCard;
