import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import { DataTable } from '@wafo/table';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faMoneyBill, faIdCard, faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/locale/es';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import confirmModal from '#components/modals/confirm/confirm';
import useUpdateCities from '#hooks/useUpdateCities';

const TableCredentialsLocations = ({ loading, cities, updateCities }) => {
  const [rawRows, setRawRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const getData = React.useCallback(async event => {
    const { size, page, search } = event;
    const [resp, error] = await of(
      roAPI.get('/rps/pagination', {
        params: {
          limit: size,
          page,
          nocache: true,
          ...(search && { search }),
        },
      }),
    );
    if (!error) {
      setRawRows(resp.rows);
      setTotalRows(parseInt(resp.count, 10));
    }
  }, []);

  useUpdateCities(updateCities, loading, false);

  const clickReload = (id = 'wafotable-reload') => {
    const btn = document.getElementById(id);
    btn.click();
  };

  const handleDelete = React.useCallback(
    id => {
      async function deletePOI() {
        loading.set();
        const [, err] = await of(roAPI.delete(`/rps/${id}`));
        if (!err) {
          clickReload();
        }
        loading.stop();
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            También será eliminado de las líneas donde aparece.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: deletePOI,
          },
        ],
      });
    },
    [loading],
  );

  const handleDisable = React.useCallback(
    (id, val) => {
      async function disableLocation() {
        loading.set();
        const [, err] = await of(
          roAPI.put(`/rps/${id}`, {
            disabled: !val,
          }),
        );
        if (!err) {
          clickReload();
        }
        loading.stop();
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            {!val ? 'La ubicación no se mostrara en el mapa.' : 'La ubicación se mostrara en el mapa.'}
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: disableLocation,
          },
        ],
      });
    },
    [loading],
  );

  const rows = rawRows.map(row => ({
    id: row.id_rp,
    type: row.type,
    city: row.id_city,
    name: row.name,
    address: row.address,
    date: row.updated_at,
    options: { id: row.id_rp, rp: row, disabled: row.disabled },
  }));

  const columnsConfig = React.useMemo(
    () => ({
      id: {
        style: {
          verticalAlign: 'middle',
        },
      },
      type: {
        render: function col(val) {
          return (
            <span className="badge badge-secondary" style={{ padding: '.25em 1rem' }}>
              {val === 1 ? <FontAwesomeIcon icon={faMoneyBill} /> : <FontAwesomeIcon icon={faIdCard} />}
            </span>
          );
        },
        style: {
          verticalAlign: 'middle',
        },
      },
      city: {
        render: function col(val) {
          const city = cities.find(x => x.id_city === val);
          return <span>{city && city.name}</span>;
        },
        style: {
          verticalAlign: 'middle',
        },
      },
      name: {
        style: {
          verticalAlign: 'middle',
          fontSize: '0.85em',
        },
      },
      address: {
        style: {
          fontSize: '0.85em',
          verticalAlign: 'middle',
        },
      },
      date: {
        render: function col(val) {
          return <span> {moment(val).format('DD MMMM YYYY HH:mm')}</span>;
        },
        style: {
          verticalAlign: 'middle',
          fontSize: '0.85em'
        },
      },
      options: {
        render: function col(val) {
          return (
            <>
              <button
                type="button"
                className={`btn btn-sm ${!val.disabled ? 'btn-success' : 'btn-warning'}`}
                onClick={() => handleDisable(val.id, val.disabled)}
                style={{ padding: '.25rem 1rem' }}
              >
                <FontAwesomeIcon icon={!val.disabled ? faToggleOn : faToggleOff} />
              </button>
              <Link
                className="btn btn-sm btn-primary"
                to={{ pathname: `/panel/credencializacion/ubicaciones/rp/${val.id}`, state: { rp: val.rp } }}
                style={{ marginRight: '.25rem' }}
              >
                <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                <span>Editar</span>
              </Link>
              <button type="button" className="btn btn-sm btn-danger" onClick={() => handleDelete(val.id)}>
                <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '.5rem' }} />
                Eliminar
              </button>
            </>
          );
        },
        style: {
          whiteSpace: 'nowrap',
          verticalAlign: 'middle',
        },
      },
    }),
    [cities, handleDisable, handleDelete],
  );

  return (
    <div>
      <DataTable
        locale="es"
        columns={['ID', 'Tipo', 'City', 'Nombre', 'Dirección', 'Última actualización', 'Opciones']}
        rows={rows}
        totalRows={totalRows}
        onPagination={getData}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        noRowsMessage="Sin puntos de credencialización o recarga"
      />
    </div>
  );
};

TableCredentialsLocations.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  cities: PropTypes.array,
  updateCities: PropTypes.func,
};

TableCredentialsLocations.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
  cities: [],
  updateCities: f => f,
};

export default reduxConnect(
  state => ({
    cities: state.cities,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableCredentialsLocations);
