import React from 'react';
import PropTypes from 'prop-types';
import BooleanButtons from '#components/forms/customForms/booleanButtons/booleanButtons';
import SelectBoundsMap from '#components/maps/selectBoundsMap/selectBoundsMap';
import ColorPicker from '#components/forms/customForms/colorPicker/colorPicker';
import ImagePicker from '#components/forms/customForms/imagePicker/imagePicker';
import Input from '../../custom/Input';
import TextArea from '../../custom/TextArea';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';

const FormCity = ({ history, match: { params }, location, loading }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [city, setCity] = React.useState(() => {
    const initialCity = {
      ne_string: '',
      sw_string: '',
      color: '#FF6A29',
      notifications_enabled: false,
      disabled: false,
    };
    if (location.state && location.state.city) {
      return {
        ...initialCity,
        ...location.state.city,
      };
    }
    return initialCity;
  });

  const initialBounds = React.useMemo(() => {
    if (location.state && location.state.city) {
      const {
        state: { city: paramCity },
      } = location;
      return {
        initialNE: [parseFloat(paramCity.ne_latitude), parseFloat(paramCity.ne_longitude)],
        initialSW: [parseFloat(paramCity.sw_latitude), parseFloat(paramCity.sw_longitude)],
      };
    }
    return {
      initialNE: null,
      initialSW: null,
    };
  }, [location]);

  const onSubmit = async values => {
    try {
      loading.set();
      const newCity = {
        name: values.name,
        description: values.description,
        color: values.color,
        // ne_latitude: values.ne_string.split(',')[0],
        // ne_longitude: values.ne_string.split(',')[1],
        // sw_latitude: values.sw_string.split(',')[0],
        // sw_longitude: values.sw_string.split(',')[1],
        ne_latitude: city.ne_latitude,
        ne_longitude: city.ne_longitude,
        sw_latitude: city.sw_latitude,
        sw_longitude: city.sw_longitude,
        notifications_enabled: !!values.notifications_enabled,
        disabled: !!values.disabled,
      };
      const res = params.id ? await roAPI.put(`/cities/${params.id}`, newCity) : await roAPI.post('/cities', newCity);
      setCity(prevCity => ({ ...prevCity, ...res }));
      if (values.logo) {
        if (typeof values.logo !== 'string') {
          const formModel = new FormData();
          formModel.append('image', values.logo);
          await roAPI.post(`/cities/${res.id_city}/logo`, formModel);
        }
      }
      if (values.banner || values.banner_url) {
        const formModel = new FormData();
        if (values.banner && typeof values.banner !== 'string') {
          formModel.append('image', values.banner);
        }
        formModel.append('banner_url', values.banner_url);
        await roAPI.post(`/cities/${res.id_city}/banner`, formModel);
      }
      history.push('/panel/ciudades');
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  };

  const handleOnBounds = React.useCallback(bounds => {
    const ne_lat = bounds.neLatLng[0].toFixed(6);
    const ne_lng = bounds.neLatLng[1].toFixed(6);
    const sw_lat = bounds.swLatLng[0].toFixed(6);
    const sw_lng = bounds.swLatLng[1].toFixed(6);
    setCity(prevBounds => ({
      ...prevBounds,
      ne_string: `${ne_lat}, ${ne_lng}`,
      sw_string: `${sw_lat}, ${sw_lng}`,
      ne_latitude: ne_lat,
      ne_longitude: ne_lng,
      sw_latitude: sw_lat,
      sw_longitude: sw_lng,
    }));
  }, []);

  const deleteBanner = async () => {
    loading.set();
    try {
      await roAPI.delete(`/cities/${params.id}/banner`);
      history.push('/panel/ciudades');
    } catch (error) {
      console.error(error);
    }
    loading.stop();
  };

  return (
    <div className="row">
      <div className="col-12">
        <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
          <span>Regresar</span>
        </button>
      </div>
      <div className="col-12">
        <form id="formCity" locale="es" onSubmit={handleSubmit(onSubmit)} values={city}>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12">
                <Input
                  type="text"
                  name="name"
                  label="Nombre"
                  placeholder="Nombre"
                  {...register('name', { required: 'Este campo es requerido' })}
                  error={errors.name}
                />
              </div>

              <div className="col-12">
                <TextArea
                  name="description"
                  label="Descripción de ciudad"
                  placeholder="Descripción de ciudad"
                  {...register('description', { required: 'Este campo es requerido' })}
                  error={errors.description}
                />
              </div>

              <div className="col-6">
                <BooleanButtons
                  name="notifications_enabled"
                  label="Notificaciones activas"
                  trueText="Activas"
                  falseText="Inactivas"
                  {...register('notifications_enabled')}
                />
              </div>

              <div className="col-6">
                <BooleanButtons
                  name="disabled"
                  label="Ciudad activa"
                  trueText="Activa"
                  falseText="Inactiva"
                  invert
                  {...register('disabled')}
                />
              </div>

              <div className="col-6">
                <ImagePicker
                  name="logo"
                  label="Icono de ciudad"
                  serverBasePath={process.env.REACT_APP_IMG_CIUDAD}
                  help="El icono debe tener un aspecto 1:1 (Ej. 600px x 600px), formato PNG y de poco peso (50KB)."
                  {...register('logo')}
                />
              </div>

              <div className="col-6">
                <ColorPicker
                  name="color"
                  label="Color de interfaz"
                  {...register('color', { required: 'Este campo es requerido' })}
                  error={errors.color}
                />
              </div>

              <div className="col-12">
                <p className="form-separator">Banner publicitario (Opcional)</p>
              </div>

              <div className="col-6">
                <ImagePicker
                  name="banner"
                  label="Banner publicitario"
                  serverBasePath={`${process.env.REACT_APP_IMG_CIUDAD}/banners`}
                  help="El banner debe tener un aspecto 4:1 (Ej. 1500px x 375px), formato PNG o JPG y de poco peso (500KB)."
                  {...register('banner')}
                />
              </div>

              <div className="col-6">
                <div className="row">
                  <div className="col-12">
                    <Input
                      type="text"
                      name="banner_url"
                      label="Enlace de banner"
                      placeholder="Enlace de banner"
                      {...register('banner_url')}
                    />
                  </div>
                  <div className="col-12">
                    {city.banner && (
                      <button type="button" className="btn btn-link btn-link-eliminar" style={{ paddingLeft: 0 }} onClick={deleteBanner}>
                        <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '.25rem' }} />
                        <span>Eliminar banner</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12">
                <label className="label">Zona de ciudad</label>
                <SelectBoundsMap
                  onBounds={handleOnBounds}
                  initialNE={initialBounds.initialNE}
                  initialSW={initialBounds.initialSW}
                />
              </div>

              <div className="col-6">
                <Input
                  type="text"
                  name="ne_string"
                  label="Noreste"
                  placeholder="0.0, 0.0"
                  {...register('ne_string')}
                  value={city.ne_string}
                  disabled
                  style={{ display: 'none' }}
                />
              </div>

              <div className="col-6">
                <Input
                  type="text"
                  name="sw_string"
                  label="Suroeste"
                  placeholder="0.0, 0.0"
                  {...register('sw_string')}
                  value={city.sw_string}
                  disabled
                  style={{ display: 'none' }}
                />
              </div>

              <div className="col-12">
                <p>Seleccione la esquina Noreste y Suroeste de la ciudad.</p>
              </div>
            </div>
          </div>
        </form>
        <button type="submit" form="formCity" className={`btn btn-une btn-submit btn-submit-center`}>
          Guardar cambios
        </button>
      </div>
    </div>
  );
};

FormCity.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.any,
  }),
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

FormCity.defaultProps = {
  history: {
    goBack: f => f,
    push: f => f,
  },
  match: {
    params: {},
  },
  location: {
    state: {},
  },
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(FormCity);
