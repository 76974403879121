import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TablePois from '#components/tables/tablePois/tablePois';
import FormPoi from '#components/forms/formPoi/formPoi';

const Pois = ({ match }) => {
  return (
    <div>
      <div className="section-header">
        <h2 className="title">Puntos de interés</h2>
        <Route
          exact
          path={`${match.url}/`}
          render={() => (
            <Link to="/panel/pois/poi" className="btn btn-primary">
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
              Agregar POI
            </Link>
          )}
        />
      </div>

      <div className="card">
        <div className="card-body">
          <Switch>
            <Route exact path={`${match.url}/`} component={TablePois} />
            <Route exact path={`${match.url}/poi`} component={FormPoi} />
            <Route exact path={`${match.url}/poi/:id`} component={FormPoi} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

Pois.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Pois.defaultProps = {
  match: {},
};

export default Pois;
