import React from 'react';
import styles from './busses.module.css';
import { WafoForm, WafoFormSelect } from '@wafo/forms';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import PropTypes from 'prop-types';
import of from 'await-of';
import { roAPI } from '#utils/axiosAPI';
import { LocalTable } from '@wafo/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import moment from 'moment';

const TableBussesStatus = ({ loading }) => {
  const [key, setKey] = React.useState(Math.random());
  const [city, setCity] = React.useState(1);
  const [type, setType] = React.useState('conexion');
  const [lines, setLines] = React.useState([]);
  const [totals, setTotals] = React.useState({});

  React.useEffect(() => {
    async function getStatus() {
      loading.set();
      const [res, err] = await of(roAPI.get(`/admin/analytics/units`));
      if (!err) {
        setLines(
          Object.values(res.results).map(x => ({
            name: x.linea_name,
            recibidos: x.recibidos,
            mostrados: x.app,
            diferencia: x.diferencia,
          })),
        );
        setTotals({
          recibidos: res.recibidos,
          mostrados: res.app,
          diferencia: res.diferencia,
          cumplimiento: res.cumplimiento,
        });
      }
      loading.stop();
    }
    async function getByLines() {
      loading.set();
      const [res, err] = await of(roAPI.get(`/admin/analytics/units/line`));
      if (!err) {
        setLines(
          res.map(x => ({
            linea: x.linea_name,
            unidad: x.code,
            date: x.date,
          })),
        );
      }
      loading.stop();
    }
    if (city) {
      if (type === 'conexion') {
        getStatus();
      } else {
        getByLines();
      }
    }
  }, [city, loading, type]);

  const columnsConfig = React.useMemo(
    () => ({
      date: val => <span>{moment(val).format('DD/MM/YYYY - HH:mm:ss')}</span>,
    }),
    [],
  );

  const downloadExcel = React.useCallback(async () => {
    loading.set();
    const [res, err] = await of(
      roAPI.get(`/admin/analytics/units/excel`, {
        responseType: 'blob',
      }),
    );
    if (!err) {
      console.log(res);
      saveAs(res.data, 'unidades.xlsx');
    }
    loading.stop();
  }, [loading]);

  const downloadExcelByLine = React.useCallback(async () => {
    loading.set();
    const [res, err] = await of(
      roAPI.get(`/admin/analytics/units/line/excel`, {
        responseType: 'blob',
      }),
    );
    if (!err) {
      console.log(res);
      saveAs(res.data, 'unidades.xlsx');
    }
    loading.stop();
  }, [loading]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <h5>Estado de autobuses</h5>
        </div>
      </div>

      <WafoForm key={key} formId="formBusses" locale="es" onSubmit={f => f}>
        <WafoFormSelect
          name="type"
          customClass="col-3"
          label="Reporte"
          defaultValue="Tipo de reporte"
          options={[
            { value: 'conexion', display: 'Estado de conexión' },
            { value: 'lineas', display: 'Por líneas' },
          ]}
          onChangeCallback={({ target: { value } }) => setType(value)}
          validations={{ required: true }}
        />

        <WafoFormSelect
          name="city"
          customClass="col-3"
          label="Ciudad"
          defaultValue="Seleccione ciudad"
          options={[{ id_city: 1, name: 'Hermosillo' }].map(x => ({ value: x.id_city, display: x.name }))}
          onChangeCallback={({ target: { value } }) => setCity(value)}
          validations={{ required: true }}
        />
      </WafoForm>

      <div className="row">
        <div className="col-12">
          <p className={styles['desc']}>
            La información mostrada es una comparativa de la información que se recibe de las unidades de transporte.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {type === 'conexion' && (
            <LocalTable
              locale="es"
              columns={['Línea', 'Recibidos', 'Mostrados en App', 'Diferencia']}
              rows={lines}
              totalRows={lines.length}
              onPagination={f => f}
              columnsConfig={columnsConfig}
              tableClass="table table-striped table-sm"
              tableWrapperClass="table-une"
              noRowsMessage={city ? 'Sin asignaciones en la ciudad' : 'Seleccione una ciudad para continuar'}
              controls={
                <React.Fragment>
                  <button type="button" className="btn btn-success" onClick={downloadExcel}>
                    <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                    <span>Descargar</span>
                  </button>
                </React.Fragment>
              }
            />
          )}
          {type === 'lineas' && (
            <LocalTable
              locale="es"
              columns={['Línea', 'Unidad', 'Últime actualización']}
              rows={lines}
              totalRows={lines.length}
              onPagination={f => f}
              columnsConfig={columnsConfig}
              tableClass="table table-striped table-sm"
              tableWrapperClass="table-une"
              noRowsMessage={city ? 'Sin asignaciones en la ciudad' : 'Seleccione una ciudad para continuar'}
              controls={
                <React.Fragment>
                  <button type="button" className="btn btn-success" onClick={downloadExcelByLine}>
                    <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                    <span>Descargar</span>
                  </button>
                </React.Fragment>
              }
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

TableBussesStatus.propTypes = {
  cities: PropTypes.array,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  updateCities: PropTypes.func,
};

TableBussesStatus.defaultProps = {
  cities: [],
  loading: {
    set: f => f,
    stop: f => f,
  },
  updateCities: f => f,
};

export default reduxConnect(
  state => ({
    cities: state.cities,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableBussesStatus);
