import React from 'react';
import MonitorMap from './monitorMap';

const Monitor = () => (
  <>
    <MonitorMap />
  </>
)

export default Monitor;
