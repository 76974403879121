import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import { DataTable } from '@wafo/table';
import { Link, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/locale/es';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import { WafoFormSelect } from '@wafo/forms';

const TableBusses = ({ loading }) => {
    const [rawRows, setRawRows] = React.useState([]);
    const [totalRows, setTotalRows] = React.useState(0);
    const [city, setCity] = React.useState('');
    const [route, setRoute] = React.useState('');
    const [cities, setCities] = React.useState([]);
    const [routes, setRoutes] = React.useState([]);

    const getData = React.useCallback(async event => {
        const { size, page, search } = event;
        const [resp, error] = await of(
            roAPI.get('/vehicles/custom', {
                params: {
                    limit: size,
                    page,
                    nocache: true,
                    ...(search && { search }),
                    ...(city && { id_city: city }),
                    ...(route && { id_route: route }),
                },
            }),
        );
        if (!error) {
            setRawRows(resp.rows);
            setTotalRows(parseInt(resp.count, 10));
        }
    }, [loading, city, route]);

    React.useEffect(() => {
        async function getCities() {
            loading.set();
            const [res] = await of(
                roAPI.get('/cities', {
                    params: { disabled: false },
                }),
            );
            setCities(res.rows);
            loading.stop();
        }
        getCities();
    }, [loading]);

    React.useEffect(() => {
        if (city != '') {
            async function getRoutes() {
                try {
                    loading.set();
                    const res = await roAPI.get(`/routes/city/${city}`, {
                        params: {
                            nocache: true,
                            disabled: '0,1',
                        },
                    });
                    setRoutes(res);
                    loading.stop();
                } catch (error) {
                    loading.stop();
                }
            }
            getRoutes();
        }
    }, [city]);

    const rows = rawRows.map(row => ({
        id: row.id_vehicle,
        code: row.code,
        city_name: row.city.name,
        route_name: row.route.name,
        status: row.disabled,
        date: row.updated_at,
        options: { id: row.id_vehicle },
    }));

    const columnsConfig = React.useMemo(
        () => ({
            status: val => (
                <span className={`badge table-badge ${!val ? 'badge-success' : 'badge-warning'}`}>
                    {!val ? 'Activo' : 'Inactivo'}
                </span>
            ),
            date: val => <span> {moment(val).format('DD MMMM YYYY HH:mm')}</span>,
            options: {
                render: function col(val) {
                    return (
                        <>
                            <Link
                                className="btn btn-sm btn-primary"
                                to={{ pathname: `/panel/autobuses/gestionar/autobus/${val.id}` }}
                                style={{ marginRight: '.25rem' }}
                            >
                                <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                                <span>Editar</span>
                            </Link>
                        </>
                    );
                },
                style: {
                    whiteSpace: 'nowrap',
                },
            },
        }),
        [],
    );

    return (
        <div>
            <div className="section-header" style={{ marginBottom: '15px' }}>
                <h5>Gestión de autobuses</h5>
                <Route
                    exact
                    path={`/panel/autobuses/gestionar/`}
                    render={() => (
                        <Link to="/panel/autobuses/gestionar/autobus" className="btn btn-primary">
                            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
                            Agregar autobús
                        </Link>
                    )}
                />
            </div>
            <DataTable
                locale="es"
                columns={['ID', 'Autobús', 'Ciudad', 'Ruta', 'Activo', 'Última actualización', 'Opciones']}
                rows={rows}
                totalRows={totalRows}
                onPagination={getData}
                columnsConfig={columnsConfig}
                tableClass="table table-striped table-sm"
                tableWrapperClass="table-une"
                noRowsMessage="No existen autobuses"
                controls={
                    <div className="row" style={{ width: '100%' }}>
                        <WafoFormSelect
                            name="city"
                            customClass="col-12 col-md-4 bus-select"
                            defaultValue="Filtre por ciudad"
                            options={cities.map(x => ({
                                value: x.id_city,
                                display: x.name,
                            }))}
                            onChangeCallback={({ target: { value } }) => setCity(value)}
                            value={city}
                        />
                        <WafoFormSelect
                            name="route"
                            customClass="col-12 col-md-4 bus-select"
                            defaultValue="Filtre por ruta"
                            options={routes.map(x => ({
                                value: x.id_route,
                                display: x.name,
                            }))}
                            onChangeCallback={({ target: { value } }) => setRoute(value)}
                            extraProps={{
                                disabled: city == '' ? true : false,
                            }}
                            value={route}
                        />
                    </div>
                }
            />
        </div>
    );
};

TableBusses.propTypes = {
    loading: PropTypes.shape({
        set: PropTypes.func,
        stop: PropTypes.func,
    }),
};

TableBusses.defaultProps = {
    loading: {
        set: f => f,
        stop: f => f,
    },
};

export default reduxConnect(
    () => ({}),
    dispatch => ({
        loading: {
            set: () => dispatch(actions.loadingSet()),
            stop: () => dispatch(actions.loadingStop()),
        },
    }),
)(TableBusses);
