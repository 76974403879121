import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faKey, faUser, faIdCard } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import InfoColumn from '#components/random/infoColumn/infoColumn';
import moment from 'moment';
import styles from './users.module.css';
import TableCredentialsLogs from '#components/tables/users/tableCredentialsLogs';
import confirmModal from '#components/modals/confirm/confirm';
import useLoading from '#hooks/redux/useLoading';
import { roAPI } from '#utils/axiosAPI';

const User = ({ location }) => {
  const [user, setUser] = React.useState(() => {
    if (location.state && location.state.user) {
      return location.state.user;
    }
    return {};
  });

  const history = useHistory();

  const loading = useLoading();

  const handleUnlink = () => {
    async function doUnlink() {
      try {
        loading.set();
        const resp = await roAPI.put(`/admin/users/${user.id_user}`, {
          curp: null,
        });
        setUser(resp);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    confirmModal({
      message: (
        <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
          El usuario no podrá ver la información de su credencial y no recibira notificaciones.
          <br />
          ¿Desea continuar?
        </p>
      ),
      buttons: [
        {
          label: 'Cancelar',
          class: 'btn-secondary',
        },
        {
          label: 'Aceptar',
          class: 'btn-une',
          onClick: doUnlink,
        },
      ],
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className={styles['header-buttons']}>
            <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
              <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
              <span>Regresar</span>
            </button>
            <div>
              <button type="button" className="btn btn-sm btn-danger" onClick={handleUnlink} disabled={!user.user_cards.length && !user.curp}>
                <FontAwesomeIcon icon={faIdCard} style={{ marginRight: '.5rem' }} />
                Desvincular credencial
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ marginBottom: '1.5rem' }}>
        <InfoColumn
          title="ID de usuario"
          info={
            <div className={styles['statuses']}>
              <span>#{user.id_user}</span>
              <span className="badge badge-secondary" style={{ padding: '.5em 1.5em' }} title="Tipo de usuario">
                <FontAwesomeIcon icon={user.type === 1 ? faKey : faUser} />
              </span>
              <span
                className={`badge ${user.user_cards.length || user.curp ? 'badge-success' : 'badge-secondary'}`}
                style={{ padding: '.5em 1em' }}
                title="Estado credencial"
              >
                <FontAwesomeIcon icon={faIdCard} />
              </span>
              <span
                className={`badge ${user.facebook_id ? 'badge-primary' : 'badge-secondary'}`}
                style={{ padding: '.5em 1em' }}
                title="Facebook"
              >
                F
              </span>
              <span
                className={`badge ${user.google_id ? 'badge-light' : 'badge-secondary'}`}
                style={{ padding: '.5em 1em' }}
                title="Google"
              >
                G
              </span>
            </div>
          }
        />
        <InfoColumn title="Nombre completo" info={`${user.name} ${user.last_name}`} />
        <InfoColumn
          title="Fecha de registro"
          info={
            <React.Fragment>
              <span title="Registro">{moment(user.created_at).format('DD MMMM YYYY HH:mm')}</span>
              <br />
              <span title="Última actualización" style={{ fontSize: '0.8em' }}>
                {moment(user.updated_at).format('DD MMMM YYYY HH:mm')}
              </span>
            </React.Fragment>
          }
        />
        <InfoColumn title="Número de teléfono" info={user.phone_number || 'N/A'} />
        <InfoColumn title="Correo electronico" info={user.email || 'N/A'} />
        <InfoColumn title="CURP" info={user.curp || 'N/A'} />
      </div>
      <div className="row">
        <div className="col-12">
          {!!user.user_cards.length && <TableCredentialsLogs card_id={user.user_cards[0].card_id} />}
          {!user.user_cards.length && !user.curp && (
            <div className={styles['no-credential']}>
              <span>El usuario no cuenta con una credencial vinculada.</span>
            </div>
          )}
          {!user.user_cards.length && user.curp && (
            <div className={styles['no-credential']}>
              <span>El usuario no cuenta con una credencial registrada.</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

User.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
};

export default User;
