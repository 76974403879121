import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import StopsMap from './stopsMap/stopsMap';

const Stops = ({ match }) => {
  return (
    <div data-testid="Notificaciones">
      <div className="header">
        <h2 className="title">Paradas de autobús</h2>
      </div>

      <div className="card">
        <div className="card-body">
          <Switch>
            <Route exact path={`${match.url}`} component={StopsMap} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

Stops.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Stops.defaultProps = {
  match: {},
};

export default Stops;
